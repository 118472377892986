import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import { InputMask } from 'primereact/inputmask';
import { locationSvg } from '../../../../Resource/images';
import './address-details-pickup-delivery-form.scss';
import AddressCard from './addressCard/addressCard';
import {
  formatPlaceholder,
  getCurrentUserId,
  isObjectBlank,
  openNotification,
  separatePickupAndDeliveryFormData,
} from '../../../../Util/commonUtility';
import { useDispatch, useSelector } from 'react-redux';
import { editAndSaveAddress } from '../../../../Store/Action/address/addressAction';
import { setLocationId } from '../../../../Store/Reducers/Address/AddressSlice';

const AddressDetailsPickupDeliveryForm = props => {
  const {
    form,
    isPickup,
    addressCardDetails,
    Fields,
    favouriteLocations,
    setOpenModel,
    setEditMode,
    pickupInitialValues,
    deliveryInitialValues,
    setPickupInitialValues,
    setDeliveryInitialValues,
    setAddressPopupInitialValues,
    addressSelectValues,
    defaultLocation,
    setIsFormEdited,
    customerId,
  } = props;

  const pickupBtns = document.getElementById('pick');
  const deliveryBtns = document.getElementById('del');
  const dispatch = useDispatch();
  const { Option } = Select;
  const { memorizeFieldsValues, locationId } = useSelector(
    state => state.AddressSlice
  );

  const addressListOptions = addressCardDetails?.map((addr, i) => {
    return {
      value: (
        <AddressCard
          key={addr?.companyName}
          addr={addr}
          setOpenModel={setOpenModel}
          setEditMode={setEditMode}
          setPickupInitialValues={setPickupInitialValues}
          setDeliveryInitialValues={setDeliveryInitialValues}
          isPickup={isPickup}
          setAddressPopupInitialValues={setAddressPopupInitialValues}
        />
      ),
      key: i,
    };
  });

  const setLocationValue = location => {
    dispatch(
      setLocationId(
        isPickup
          ? {
              ...locationId, // change state name id to location details
              pickupId: location?._id,
              favoritePickupLocationFor: location?.favoritePickupLocationFor,
            }
          : {
              ...locationId,
              deliveryId: location?._id,
              favoriteDeliveryLocationFor:
                location?.favoriteDeliveryLocationFor,
            }
      )
    );

    if (isPickup) {
      setPickupInitialValues(location);
    } else {
      setDeliveryInitialValues(location);
    }
  };

  const addLocationHandler = () => {
    setOpenModel(true);
    setEditMode(false);
    setAddressPopupInitialValues({});
  };

  const addressSelectOnChange = (value, isPickup) => {
    dispatch(
      setLocationId(
        isPickup
          ? {
              ...locationId,
              pickupId: value?.props?.addr?._id,
              favoritePickupLocationFor:
                value?.props?.addr?.favoritePickupLocationFor,
            }
          : {
              ...locationId,
              deliveryId: value?.props?.addr?._id,
              favoriteDeliveryLocationFor:
                value?.props?.addr?.favoriteDeliveryLocationFor,
            }
      )
    );
  };

  const formStatus = obj => {
    for (const key in obj) {
      if (key.includes('ForDelivery') && obj[key] === undefined) {
        return 'delivery';
      } else if (obj[key] === undefined) {
        return 'pickup';
      }
    }
    return false;
  };

  const onFeildsChange = e => {
    if (e?.length === 1) {
      const separateValue = separatePickupAndDeliveryFormData(
        form.getFieldsValue()
      );
      const isForDelivery = e[0]?.name[0].includes('ForDelivery');

      const targetBtns = isForDelivery ? deliveryBtns : pickupBtns;
      const isTargetBlank = isForDelivery
        ? isObjectBlank(separateValue?.delivery)
        : isObjectBlank(separateValue?.pickup);
      if (!isTargetBlank) {
        targetBtns.style.display = 'block';
        setIsFormEdited(true);
      }
    }
  };

  useEffect(() => {
    //side effect for setting default value
    if (!memorizeFieldsValues && defaultLocation) {
      setPickupInitialValues(defaultLocation);
    }
  }, [defaultLocation]);

  const editCurrentLocationHandler = async isPickup => {

    const formDataObj = form.getFieldValue();
    const formFieldStatus = formStatus(formDataObj);
    if (
      (isPickup && formFieldStatus === 'pickup') ||
      (!isPickup && formFieldStatus === 'delivery')
    ) {
      openNotification('error', 'Error', 'Please enter Details');
      return;
    }

    const customerOnTimeId = getCurrentUserId();
    if (isPickup) {
      const addressObj = {
        _id: pickupInitialValues?._id || locationId?.pickupId,
        companyName: formDataObj?.CompanyName,
        contactName: formDataObj?.ContactName,
        addressLine1: formDataObj?.AddressLine1,
        addressLine2: formDataObj?.AddressLine2,
        comments: formDataObj?.Comments,
        city: formDataObj?.City,
        postalCode: formDataObj?.PostalCode,
        email: formDataObj?.Email,
        phone: formDataObj?.Phone,
        customerOnTimeId,
        customerId,
        isFavoritePickupLocation:
          pickupInitialValues?.favoritePickupLocationFor?.length > 0
            ? pickupInitialValues?.favoritePickupLocationFor
            : false,
        isFavoriteDeliveryLocation:
          pickupInitialValues?.favoriteDeliveryLocationFor?.length > 0
            ? pickupInitialValues?.favoriteDeliveryLocationFor
            : false,
        isDefaultLocation:
          pickupInitialValues?.defaultLocationFor?.length > 0
            ? pickupInitialValues?.defaultLocationFor
            : false,
        latitudeLongitude: pickupInitialValues?.latitudeLongitude,
      };
      dispatch(
        editAndSaveAddress({
          id: addressObj?._id,
          data: addressObj,
          type: 'edit',
        })
      );
      pickupBtns.style.display = 'none';
      if (
        !(pickupBtns?.style.display === 'block') &&
        !(deliveryBtns?.style.display === 'block')
      ) {
        setIsFormEdited(false);
        return;
      } else {
        setIsFormEdited(true);
      }
      return;
    } else {
      const addressObj = {
        _id: deliveryInitialValues?._id || locationId?.deliveryId,
        companyName: formDataObj?.CompanyNameForDelivery,
        contactName: formDataObj?.ContactNameForDelivery,
        addressLine1: formDataObj?.AddressLine1ForDelivery,
        addressLine2: formDataObj?.AddressLine2ForDelivery,
        comments: formDataObj?.CommentsForDelivery,
        city: formDataObj?.CityForDelivery,
        postalCode: formDataObj?.PostalCodeForDelivery,
        email: formDataObj?.EmailForDelivery,
        phone: formDataObj?.PhoneForDelivery,
        customerOnTimeId,
        customerId,
        isFavoritePickupLocation:
          deliveryInitialValues?.favoritePickupLocationFor?.length > 0
            ? deliveryInitialValues?.favoritePickupLocationFor
            : false,
        isFavoriteDeliveryLocation:
          deliveryInitialValues?.favoriteDeliveryLocationFor?.length > 0
            ? deliveryInitialValues?.favoriteDeliveryLocationFor
            : false,
        isDefaultLocation:
          deliveryInitialValues?.defaultLocationFor?.length > 0
            ? deliveryInitialValues?.defaultLocationFor
            : false,
        latitudeLongitude: deliveryInitialValues?.latitudeLongitude,
      };
      dispatch(
        editAndSaveAddress({
          id: addressObj?._id,
          data: addressObj,
          type: 'edit',
        })
      );
    }
    setIsFormEdited(false);
    deliveryBtns.style.display = 'none';
    if (
      !(pickupBtns?.style.display === 'block') &&
      !(deliveryBtns?.style.display === 'block')
    ) {
      setIsFormEdited(false);
      return;
    } else {
      setIsFormEdited(true);
    }
  };
  const addCurrentLocationHandler = async () => {
    const formDataObj = form.getFieldValue();
    const formFieldStatus = formStatus(formDataObj);
    if (
      (isPickup && formFieldStatus === 'pickup') ||
      (!isPickup && formFieldStatus === 'delivery')
    ) {
      openNotification('error', 'Error', 'Please enter Details');
      return;
    }

    const customerOnTimeId = getCurrentUserId();
    if (isPickup) {
      const addressObj = {
        //create common function
        companyName: formDataObj?.CompanyName,
        contactName: formDataObj?.ContactName,
        addressLine1: formDataObj?.AddressLine1,
        addressLine2: formDataObj?.AddressLine2,
        comments: formDataObj?.Comments,
        city: formDataObj?.City,
        postalCode: formDataObj?.PostalCode,
        email: formDataObj?.Email,
        phone: formDataObj?.Phone,
        customerOnTimeId,
        customerId,
        state: pickupInitialValues?.state,
        country: pickupInitialValues?.country,
        isFavoritePickupLocation:
          pickupInitialValues?.favoritePickupLocationFor?.length > 0
            ? pickupInitialValues?.favoritePickupLocationFor
            : false,
        isFavoriteDeliveryLocation:
          pickupInitialValues?.favoriteDeliveryLocationFor?.length > 0
            ? pickupInitialValues?.favoriteDeliveryLocationFor
            : false,
        isDefaultLocation:
          pickupInitialValues?.defaultLocationFor?.length > 0
            ? pickupInitialValues?.defaultLocationFor
            : false,
        latitudeLongitude: pickupInitialValues?.latitudeLongitude,
      };
      dispatch(
        editAndSaveAddress({
          id: addressObj?._id,
          data: addressObj,
          type: 'add',
        })
      );
      pickupBtns.style.display = 'none';
    } else {
      const addressObj = {
        companyName: formDataObj?.CompanyNameForDelivery,
        contactName: formDataObj?.ContactNameForDelivery,
        addressLine1: formDataObj?.AddressLine1ForDelivery,
        addressLine2: formDataObj?.AddressLine2ForDelivery,
        comments: formDataObj?.CommentsForDelivery,
        city: formDataObj?.CityForDelivery,
        postalCode: formDataObj?.PostalCodeForDelivery,
        email: formDataObj?.EmailForDelivery,
        phone: formDataObj?.PhoneForDelivery,
        customerOnTimeId,
        customerId,
        state: deliveryInitialValues?.state,
        country: deliveryInitialValues?.country,
        isFavoritePickupLocation:
          deliveryInitialValues?.favoritePickupLocationFor?.length > 0
            ? deliveryInitialValues?.favoritePickupLocationFor
            : false,
        isFavoriteDeliveryLocation:
          deliveryInitialValues?.favoriteDeliveryLocationFor?.length > 0
            ? deliveryInitialValues?.favoriteDeliveryLocationFor
            : locationId.favoriteDeliveryLocationFor?.length > 0
            ? locationId.favoriteDeliveryLocationFor
            : false,
        isDefaultLocation:
          deliveryInitialValues?.defaultLocationFor?.length > 0
            ? deliveryInitialValues?.defaultLocationFor
            : false,
        latitudeLongitude: deliveryInitialValues?.latitudeLongitude,
      };
     
      dispatch(
        editAndSaveAddress({
          id: addressObj?._id,
          data: addressObj,
          type: 'add',
        })
      );
    }
    setIsFormEdited(false);
    deliveryBtns.style.display = 'none';
  };

  useEffect(() => {
    if (memorizeFieldsValues) {
      setPickupInitialValues({
        _id: locationId?.pickupId,
        companyName: memorizeFieldsValues?.['CompanyName'],
        contactName: memorizeFieldsValues?.['ContactName'],
        addressLine1: memorizeFieldsValues?.AddressLine1,
        addressLine2: memorizeFieldsValues?.['AddressLine2'],
        comments: memorizeFieldsValues?.['Comments'],
        city: memorizeFieldsValues?.City,
        postalCode: memorizeFieldsValues?.['PostalCode'],
        email: memorizeFieldsValues?.['Email'],
        phone: memorizeFieldsValues?.['Phone'],
        latitudeLongitude: memorizeFieldsValues?.['LatitudeLongitude'],
        favoritePickupLocationFor: locationId?.favoritePickupLocationFor,
      });
      setDeliveryInitialValues({
        _id: locationId?.deliveryId,
        companyName: memorizeFieldsValues?.['CompanyNameForDelivery'],
        contactName: memorizeFieldsValues?.['ContactNameForDelivery'],
        addressLine1: memorizeFieldsValues?.AddressLine1ForDelivery,
        addressLine2: memorizeFieldsValues?.['AddressLine2ForDelivery'],
        comments: memorizeFieldsValues?.['CommentsForDelivery'],
        city: memorizeFieldsValues?.CityForDelivery,
        postalCode: memorizeFieldsValues?.['PostalCodeForDelivery'],
        email: memorizeFieldsValues?.['EmailForDelivery'],
        phone: memorizeFieldsValues?.['PhoneForDelivery'],
        latitudeLongitude:
          memorizeFieldsValues?.['LatitudeLongitudeForDelivery'],
        favoriteDeliveryLocationFor: locationId?.favoriteDeliveryLocationFor,
      });
    }
  }, []);

  useEffect(() => {
    //side effect for setting fields value
    form.setFieldsValue({
      CompanyName: pickupInitialValues?.['companyName'],
      ContactName: pickupInitialValues?.['contactName'],
      AddressLine1: pickupInitialValues?.addressLine1,
      AddressLine2: pickupInitialValues?.['addressLine2'],
      Comments: pickupInitialValues?.['comments'],
      City: pickupInitialValues?.city,
      PostalCode: pickupInitialValues?.['postalCode'],
      Email: pickupInitialValues?.['email'],
      Phone: pickupInitialValues?.['phone'],
    });
    if (!isPickup) {
      form.setFieldsValue({
        CompanyNameForDelivery: deliveryInitialValues?.['companyName'],
        ContactNameForDelivery: deliveryInitialValues?.['contactName'],
        AddressLine1ForDelivery: deliveryInitialValues?.addressLine1,
        AddressLine2ForDelivery: deliveryInitialValues?.['addressLine2'],
        CommentsForDelivery: deliveryInitialValues?.['comments'],
        CityForDelivery: deliveryInitialValues?.city,
        PostalCodeForDelivery: deliveryInitialValues?.['postalCode'],
        EmailForDelivery: deliveryInitialValues?.['email'],
        PhoneForDelivery: deliveryInitialValues?.['phone'],
      });
      return;
    }
  }, [pickupInitialValues, deliveryInitialValues]);

  const checkIsFormFilled = () => {
    if (pickupInitialValues && deliveryInitialValues) {
      return isPickup && (Object.keys(pickupInitialValues)?.length > 0) ? true : !isPickup && (Object.keys(deliveryInitialValues)?.length > 0) ? true : false;
    }
    return false
  }

  return (
    <div className='pickup-form'>
      <header>
        <span
          className={isPickup ? 'pickup-form-label' : 'delivery-form-label'}
        >
          <h3>{isPickup ? 'Pickup At' : 'Deliver To'}</h3>
        </span>
        <button className='add-addr-btn' onClick={addLocationHandler}>
          <span className='pi pi-plus-circle'></span>Add New Location
        </button>
      </header>
      <div className='search-address-wrapper'>
        <Form.Item
          label={'Search Address Book'}
          prefixCls='location-select'
          required
          name={'select-location'}
        >
          <Select
            showSearch
            allowClear
            className='address-select'
            onSelect={e => addressSelectOnChange(e, isPickup)}
            style={{
              width: '100%',
            }}
            clearIcon={<button className='allow-clear-btn'>clear</button>}
            value={addressSelectValues}
            placeholder='Search Company'
            optionFilterProp='children'
            filterOption={(input, option) =>
              (
                option?.value.props.addr?.companyName?.toLowerCase() ?? ''
              ).includes(input.toLowerCase())
            }
          >
            {addressListOptions?.map(option => (
              <Option key={option.key} value={option.value}></Option>
            ))}
          </Select>
        </Form.Item>
      </div>
        <div className='fav-location'>
          <div className='fav-location-label'>
            <img src={locationSvg} alt='' />
            Favourite Location
          </div>
          <div className='fav-list'>
            { favouriteLocations?.length > 0 ? favouriteLocations?.map(location => (
              <span
                className='card'
                onClick={() => setLocationValue(location)}
                key={location?._id}
              >
                {location?.companyName}
              </span>
            )) : <span className='fav-loc-empty-state'>No favorite location added</span>
          }
          </div>
        </div>
      { checkIsFormFilled() &&
        <div className='form-wrapper'>
          <Form
            form={form}
            className='address-details-form'
            onFieldsChange={onFeildsChange}
          >
            <div className='form-line'>
              {Fields?.NestedField?.map(field => {
                return FormFieldGenerator(
                  ['ContactName', 'CompanyName'],
                  field,
                  isPickup
                );
              })}
            </div>
            {Fields?.NestedField?.map(field => {
              return FormFieldGenerator(
                ['AddressLine1', 'AddressLine2', 'Comments'],
                field,
                isPickup
              );
            })}

            <div className='form-line'>
              {Fields?.NestedField?.map(field => {
                return FormFieldGenerator(
                  ['City', 'PostalCode'],
                  field,
                  isPickup
                );
              })}
            </div>

            {Fields?.NestedField?.map(field => {
              return FormFieldGenerator(['Email'], field, isPickup);
            })}

            <div className='form-line-footer'>
              {Fields?.NestedField?.map(field => {
                return FormFieldGenerator(
                  ['Phone', 'PhoneExt'],
                  field,
                  isPickup
                );
              })}
            </div>
          </Form>
          <div id={isPickup ? 'pick' : 'del'} style={{ display: 'none' }}>
            <div className={'footer-btns'}>
              <button
                className='btn'
                onClick={() => editCurrentLocationHandler(isPickup)}
              >
                Edit current location
              </button>
              <button
                className='btn'
                onClick={() => addCurrentLocationHandler(isPickup)}
              >
                Add as new location
              </button>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default AddressDetailsPickupDeliveryForm;

const FormFieldGenerator = (conditonArr, field, isPickup) => {
const fieldsKey = {
  ContactName: 'Contact Name',
  CompanyName: 'Company Name',
  AddressLine1: 'Address Line 1',
  AddressLine2: 'Address Line 2',
  City: 'City',
  Comments: 'Comments',
  Email: 'Email',
  PostalCode: 'Postal Code',
  PhoneExt: 'PhoneExt',
  Phone: 'Phone'
}
// const errorMessage = field.Name === 'ContactName' ? 'Contact Name is Required': field.Name === 'CompanyName' ? 'Company Name is Required' : ''
  if (conditonArr?.includes(field?.Name)) {
    return (
      field?.IsDisplay && (
        <Form.Item
          key={field?._id}
          name={isPickup ? field?.Name : `${field?.Name}ForDelivery`}
          rules={[
            {
              required: field?.IsRequired,
              message: `${fieldsKey[field?.Name]}  is required`,
            },
          ]}
        >
          {field.Name === 'Phone' ? (
            <InputMask
              mask={'(999)-999-9999'}
              autoClear={false}
              type='tele'
              placeholder='(999) 999-9999'
              className='popup-inputs masked-input'
            />
          ) : (
            <Input
              placeholder={formatPlaceholder(field?.Name)}
              className='popup-inputs'
              disabled={
                field.Name === 'AddressLine1' ||
                field.Name === 'City' ||
                field.Name === 'PostalCode'
                  ? true
                  : false
              }
            />
          )}
        </Form.Item>
      )
    );
  }
};
