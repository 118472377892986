import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { searchHighlight } from '../../../../Services/agGrid/addressListGrid';
import {
  getCurrentUserId,
} from '../../../../Util/commonUtility';
import { useNavigate } from 'react-router-dom';
import { setGridColumsMenu } from '../../../../Store/Action/order/orderAction';
import { useDispatch } from 'react-redux';

const OrderGrid = ({
  inputRef,
  setGridApi,
  data,
  colObj,
  colDef,
  setDeleteDraftModel,
  setDraftIdForDelete,
  editDraftOrder,
  setSelectedColumn,
  onCellClicked
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate();



  const cellRenderer = p => {
    return searchHighlight(p, inputRef);
  };
  const columns = colDef?.map(col => {
    return {
      field: col.field,
      headerName: colObj[col.field],
      filter: col.field === 'Edit' || col.field === 'Delete' ? false : true,
      resizable: true,
      hide: col.hide || false,
      lockVisible: false,
      unSortIcon: col.field === 'Edit' || col.field === 'Delete' ? false : true,
      cellRenderer: cellRenderer,
    };
  });

  const onGridReady = params => {
    setGridApi(params);
  };

  const onDragStopped = async data => {
    const customerId = getCurrentUserId();
    const gridState = await data.columnApi.getColumnState();
    const finalGrid = gridState.map(item => {
      const key = Object.keys(colObj).find(
        colKey => {
          return colObj[colKey] === item.colId
        }
      );
      return { ...item, colId: key };
    });
    // const finalGrid = gridState.map(col => {
      //   return {...col, colId: colObj[col.colId]}
    // })
    localStorage.setItem(
      `${customerId}_ColumnStateSaveOrderList`,
      JSON.stringify(finalGrid)
      );
      const newCols = gridState.map(col => {
        return { field: col.colId, hide: col.hide };
      });
      setSelectedColumn(newCols);

    const maskedData = {
      ColumnStateSaveOrderList: JSON.stringify(finalGrid),
      ContactId: customerId,
    };
  
    dispatch(setGridColumsMenu({grid: JSON.stringify(finalGrid)}))
    // await VNP_API.put(
    //   `${Urls.ORDER_COLUMN_STATE_API}/${customerId}`,
    //   maskedData
    // );
  };

  return (
    <div className='ag-theme-alpine' style={{ height: '100%' }}>
      <AgGridReact
        rowHeight={'25'}
        headerHeight={'30'}
        rowData={data}
        columnDefs={columns}
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        onDragStopped={onDragStopped}
        tooltipMouseTrack={true}
        suppressAnimationFrame={true}
        onGridReady={onGridReady}
        onCellClicked={onCellClicked}
        defaultColDef={{ sortable: true }}
        pagination={true}
      />
    </div>
  );
};

export default OrderGrid;
