import './App.scss';
import './Scss/application.scss';
import AppRoute from './Routes/appRoute';
import Spinner from './Components/Common/Spinner/spinner';
import { cheakIsUserLogin, checkIsOnline } from './Util/commonUtility';
import Notification from './Components/Common/Notification/notification';
import 'primeicons/primeicons.css';

checkIsOnline();
cheakIsUserLogin();

function App() {

  return (
    <>
    <Notification />
    <Spinner />
    <AppRoute />
    </>
  );
}

export default App;
