import React from 'react';
import { Form, Input } from 'antd';
import { emailSvg } from '../../Resource/images';
import './login-form.scss';
import { passwordSvg } from '../../Resource/images';
import { commonConfig } from '../../Config/config';

const LoginForm = ({ submiteHandler }) => {
  return (
    <div className='login-form-wrapper'>
      <h1>Log in</h1>
      <span className='loginDesc'>
       { `Welcome to ${commonConfig.CompanyName} Portal, Please Enter Your Details.`}
      </span>
      <Form className='login-form' onFinish={submiteHandler}>
        <div className='auth-input-wrapper'>
          <img src={emailSvg} alt='' className='icon-email' />
          <Form.Item
            name='username'
            label='Email'
            className='auth-input-wrapper'
            rules={[{ required: true, message: 'Email is required' }]}
          >
            <Input
              className='customInputStyle'
              autoComplete='username'
              placeholder='Enter Your Email Address'
            />
          </Form.Item>
        </div>

        <div className='auth-input-wrapper'>
          <img src={passwordSvg} alt='' className='icon-password' />
          <Form.Item
            name='password'
            label='Password'
            className='auth-input-wrapper'
            rules={[{ required: true, message: 'Password is required' }]}
          >
            <Input.Password
            autoComplete='current-password'
              className='customInputStyle'
              placeholder='Enter Your Password'
            />
          </Form.Item>
        </div>

        {/* <span className='remember-span'>
          <input type='checkbox' name='' id='remember' className='remember' />
          <label htmlFor='remember' className='loginDesc'>
            Remember me next time
          </label>
        </span> */}

        <button className='btnSignin'>Login</button>
      </Form>
    </div>
  );
};

export default LoginForm;
