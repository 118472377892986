import React from 'react';
import MainLayout from '../../Components/Layout/mainLayout.js';
import OrderTracking from '../../Containers/Order/tracking/orderTracking.jsx';

const OrderTrackingScreen = () => {
  return (
    <MainLayout>
      <OrderTracking />
    </MainLayout>
  );
};

export default OrderTrackingScreen;
