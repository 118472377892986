import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import Auth from '../Screens/auth/authScreen';

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();

  const { isUserLogin: isLogin } = useSelector(state => state.AuthSlice);
  useEffect(() => {
    !isLogin && navigate('/login');
  }, []);
  return <>{isLogin ? children : <Navigate to={'/login'} />}</>;
};

export default ProtectedRoute;
