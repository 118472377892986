export const orderFilterBtnsENUMS = {
  allOrders: 'All Orders',
  draft: 'Draft',
  submitted: 'Submitted',
  inTransit: 'In-Transit',
  delivered: 'Delivered',
};

export const navLinksEnums = {
  orderEntry: 'Order Entry',
  myOrders: 'My Orders',
  addressBook: 'Address Book',
  myInvoice: 'My Invoice',
};
