import React, { useEffect, useState } from 'react';
import './selectShipment.scss';
import ShipmentServices from '../../../../Components/Order/Entry/selectShipment/shipmentServices';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../Components/Order/Entry/selectShipment/shipment-services.scss';
import {
  recalculatePriceMod,
  submitQuote,
} from '../../../../Store/Action/order/orderAction';
import { Empty, Form } from 'antd';
import { calculatePriceWithTax } from '../../../../Util/commonUtility';
import { setShowQuote } from '../../../../Store/Reducers/Order/orderSlice';
import { setMemorizeFieldsValues } from '../../../../Store/Reducers/Address/AddressSlice';
import { commonConfig } from '../../../../Config/config';

const SelectShipment = ({
  changedPriceMod,
  setChangedPriceMod,
  selectedService,
  setSelectedServices,
}) => {
  const dispatch = useDispatch();
  const { quoteData, settings, recalculatedPrice, quoteSubmitted } =
    useSelector(state => state.OrderSlice);
  const [shipmentServices, setShipmentServices] = useState([]);
  const [filteredPM, setFilteredPM] = useState([]);
  const [finalPrice, setfinalPrice] = useState('');
  const [currentServiceName, setCurrentServiceName] = useState(''); // for render price modifier conditionally
  const {
    maskedPackages: Packages,
    packageDescription,
    showQuote,
  } = useSelector(state => state.OrderSlice);
  const { placeOrderSteperDetails, locationId, memorizeFieldsValues } =
    useSelector(state => state.AddressSlice);
  const { tax } = settings;
  const [form] = Form.useForm();

  useEffect(() => {
    openPriceModContainer(memorizeFieldsValues?.selectedService);
  }, [shipmentServices]);

  useEffect(() => {
    if (showQuote) {
      setShipmentServices([]);
      return;
    }
    setShipmentServices(renderQuotes(quoteData));
  }, [quoteData]);

  const filterState = (service, state) => {
    return service.PriceModifiers?.filter(
      service => service.SelectedState === state && service.Visibility === true
    );
  };

  const filterePriceModi = service => {
    const notSelected = filterState(service, 'NotSelected');
    const selected = filterState(service, 'Selected');
    const disabled = filterState(service, 'Required');

    return [...notSelected, ...selected, ...disabled];
  };

  const openPriceModContainer = id => {
    dispatch(
      setMemorizeFieldsValues({
        ...memorizeFieldsValues,
        selectedService: id,
      })
    );
    const selectBtn = document.getElementById(id);
    selectBtn && selectBtn.classList.add('active');
    const elms = document.getElementsByClassName(`priceMod-conatiner`);
    const btns = document.getElementsByClassName(`select-btn`);

    Array.from(elms).forEach(elm => {
      elm.classList.contains(id)
        ? (elm.style.display = 'table-row')
        : (elm.style.display = 'none');
    });
    Array.from(btns).forEach(btn => {
      btn.classList.contains(`${id}-btn`)
        ? btn.classList.add('active')
        : btn.classList.remove('active');
    });
  };

  useEffect(() => {
    if (showQuote) {
      setShipmentServices([]);
    }
  }, [showQuote]);

  useEffect(() => {
    return () => {
      dispatch(setShowQuote(false));
    };
  }, []);

  useEffect(() => {
    //refreshPrice
    if (recalculatedPrice) {
      setfinalPrice(
        calculatePriceWithTax(tax, recalculatedPrice, currentServiceName)
      );
    }
  }, [recalculatedPrice]);

  const PriceModifies = (bool, id, pm) => {
    if (!bool) {
      const filteredPriceModi = filteredPM?.filter(pm => pm?.ID !== id);
      setFilteredPM(filteredPriceModi);
    } else {
      const filterRequiredsMod = filteredPM?.filter(pm => {
        if (
          pm?.SelectedState === 'Required' ||
          pm?.SelectedState === 'Selected'
        ) {
          return pm;
        }
      });
            const isExist = filterRequiredsMod.some(pm => pm?.ID === id);
      if (!isExist) {
        setFilteredPM([...filteredPM, pm]);
      }
    }
    setChangedPriceMod(true);
  };

  const selectShipmentHandler = (id, service, name) => {
    setCurrentServiceName(name);
    form.resetFields();
    openPriceModContainer(id);
    setSelectedServices(service);
    const filterRequiredsMod = service.service?.PriceModifiers?.filter(pm => {
      if (
        pm?.SelectedState === 'Required' ||
        pm?.SelectedState === 'Selected'
      ) {
        return pm;
      }
    });
    setFilteredPM(filterRequiredsMod);
    setChangedPriceMod(false);
  };

  const pickupWithId = {
    ...placeOrderSteperDetails.CollectionLocation,
    ID: locationId.pickupId,
    Zone: {},
  }; // create Common function for
  const deliveryWithId = {
    ...placeOrderSteperDetails.DeliveryLocation,
    ID: locationId.deliveryId,
    Zone: {},
  };

  const recalculatePrice = () => {
    const maskedObj = {
      CollectionLocation: { ...pickupWithId },
      DeliveryLocation: { ...deliveryWithId },
      CustomerId: placeOrderSteperDetails.CustomerId,
      CustomerOnTimeId: placeOrderSteperDetails.CustomerOnTimeId,
      Packages,
      Quote: selectedService.service,
      SelectedProviderType:
        selectedService?.service?.provider === 'VNP' ? 'VNP' : 'DLVRD',
      TaxArray: tax?.Taxes,
      selectedPriceModi: filteredPM,
      Description: packageDescription,
    };
        dispatch(recalculatePriceMod({ data: maskedObj, tax }));
  };

  const getQuoteSubmitted = () => {
    const maskedObj = {
      CollectionLocation: { ...pickupWithId },
      DeliveryLocation: { ...deliveryWithId },
      CustomerId: placeOrderSteperDetails.CustomerId,
      CustomerOnTimeId: placeOrderSteperDetails.CustomerOnTimeId,
      Packages,
    };
    dispatch(submitQuote({ payload: maskedObj, bool: true }));
  };

  const renderQuotes = quoteArray => {
    let arr = [];
    const recursive = (quoteArray, name, id, isOutside) => {
      quoteArray.forEach(quote => {
        if (quote.data) {
          quote.data.forEach(service => {
            if (service.isFromOutside === true) {
              recursive(
                service.data,
                quote.provider,
                quote.providerId,
                service.isFromOutside
              );
            } else {
              if (service.data) {
                recursive(service.data, quote.provider);
              } else {
                arr.push({
                  service,
                  parentProviderName: quote.provider === 'OnTime360' ? 'VNP' : quote.provider,
                  parentProviderId: quote.providerId,
                  isFromOutside: service.isFromOutside || false,
                });
              }
            }
          });
        } else {
          arr.push({
            service: quote,
            parentProviderName: name === 'OnTime360' ? 'VNP' : name,
            parentProviderId: id,
            isFromOutside: isOutside,
          });
        }
      });
    };

    recursive(quoteArray);
    return arr.flat(1);
  };
  return (
    <div className='shipment-container'>
      <header className='label-wrapper'>
        <span>Select Shipment</span>
      </header>
      <main className='shipment-list-container'>
        <table className='shipment-table'>
          <thead>
            <tr>
              <th>Carrier Service</th>
              <th>Estimated Delivery</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shipmentServices?.length > 0 ? (
              shipmentServices?.map((service, i) => (
                <ShipmentServices
                  key={i}
                  service={service}
                  tax={tax}
                  PriceModifies={PriceModifies}
                  selectShipmentHandler={selectShipmentHandler}
                  filterePriceModi={filterePriceModi}
                  finalPrice={finalPrice}
                  form={form}
                />
              ))
            ) : //)
            shipmentServices.length <= 0 && !showQuote ? (
              <tr className='quote-row'>
                <td colSpan={8} className='no-service-container'>
                  No Service Available, Please Contact{' '}
                  <span className='no-service'>
                    {commonConfig.vnpContactNumber}
                  </span>{' '}
                  or <span className='no-service'>{commonConfig.vnpEmail}</span>
                </td>
              </tr>
            ) : showQuote === true ? (
              <tr className='quote-row'>
                <td colSpan={4} className='quote-container'>
                  {quoteSubmitted.IsQuote === true ? (
                    <div className='thanks-container'>
                      <p>Thanks For Contacting us.</p>
                      <p>
                        We got your requirements from{' '}
                        <span>
                          {
                            placeOrderSteperDetails.CollectionLocation
                              ?.PostalCode
                          }
                        </span>{' '}
                        to{' '}
                        <span>
                          {
                            placeOrderSteperDetails?.DeliveryLocation
                              ?.PostalCode
                          }
                        </span>{' '}
                        to provide in the quote
                      </p>
                      <p>We will contact you soon.</p>
                    </div>
                  ) : (
                    <div className='quote-txt-wrapper'>
                      <p>
                        Standard Price Not Available For Selected &nbsp;
                        <span>
                          {
                            placeOrderSteperDetails?.CollectionLocation
                              ?.PostalCode
                          }
                        </span>
                        &nbsp; to &nbsp;
                        <span>
                          {placeOrderSteperDetails.DeliveryLocation?.PostalCode}
                        </span>
                      </p>
                      <h4>click below to Get a Quote</h4>
                      <button
                        className='get-quote-btn'
                        onClick={getQuoteSubmitted}
                      >
                        Get Quote Now!
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ) : (
              ''
            )}
          </tbody>
        </table>

        {changedPriceMod && (
          <footer onClick={() => setChangedPriceMod(false)}>
            <hr />
            <button className='re-cal-price-btn' onClick={recalculatePrice}>
              Recalculate the Price
            </button>
          </footer>
        )}
      </main>
    </div>
  );
};

export default SelectShipment;
