import { createSlice } from '@reduxjs/toolkit';

const addressSlice = createSlice({
  name: 'Address',
  initialState: {
    addresses: [],
    memorizeFieldsValues: null,
    placeOrderSteperDetails: {},
    locationId: {},
    latitudeLongitude: {},
    vnpOrderEntryFields: {}
  },
  reducers: {
    addressList(state, { payload }) {
      state.addresses = payload;
    },
    setMemorizeFieldsValues(state, { payload }) {
      state.memorizeFieldsValues = payload;
    },
    setPlaceOrderSteperDetails(state, { payload }) {
      state.placeOrderSteperDetails = payload;
    },
    setLatitudeLongitude(state, { payload }) {
      state.latitudeLongitude = payload;
    },
    setLocationId(state, { payload }){
      state.locationId = payload;
    },
    setVnpOrderEntryFields(state, { payload }){
      state.vnpOrderEntryFields = payload
    }
  },
});

export const {
  addressList,
  setMemorizeFieldsValues,
  setPlaceOrderSteperDetails,
  setLatitudeLongitude,
  setLocationId,
  setVnpOrderEntryFields
} = addressSlice.actions;
export default addressSlice.reducer;
