import React, { useEffect, useState } from 'react';
import './addressDetails.scss';
import AddressDetailsPickupDeliveryForm from '../../../../Components/Order/Entry/addressDetails/addressDetailsPickupDeliveryForm';
import { swapSvg } from '../../../../Resource/images';
import { useDispatch, useSelector } from 'react-redux';
import { getAddressList } from '../../../../Store/Action/address/addressAction';
import { getUser } from '../../../../Store/Action/user/userAction';
import { getCurrentUserId } from '../../../../Util/commonUtility';
import AddressPopup from '../../../../Components/Address/popup/addressPopup';
import {
  setLatitudeLongitude,
  setLocationId,
} from '../../../../Store/Reducers/Address/AddressSlice';

const AddressDetails = ({ form, setIsFormEdited }) => {
  const dispatch = useDispatch();
  const {
    addresses,
    latitudeLongitude,
    placeOrderSteperDetails,
    vnpOrderEntryFields,
    locationId,
  } = useSelector(state => state.AddressSlice);
  const userDetails = useSelector(state => state.AuthSlice);
  const [openModel, setOpenModel] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [pickupInitialValues, setPickupInitialValues] = useState({});
  const [addressPopupInitialValues, setAddressPopupInitialValues] = useState(
    {}
  );
  const [defaultLocation, setDefaultLocation] = useState({});
  const [deliveryInitialValues, setDeliveryInitialValues] = useState({});
  const [favouriteLocationsObj, setFavouriteLocationsObj] = useState({});
  const { userObjId } = userDetails;
  const { Customer_ontime_id } = userDetails.userData;
  const currentCustomerId = getCurrentUserId();

  const getDefaultLocation = addresses => {
    let locationArr = [];
    for (let i = 0; i < addresses?.length; i++) {
      let addr = addresses[i];

      const arr = addr?.defaultLocationFor?.filter(
        id => id?.customerId === currentCustomerId
      );
      if (arr.length > 0) {
        locationArr.push(addr);
      }
    }
    return locationArr[locationArr?.length - 1]; // returning the last location only
  };
  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    dispatch(
      setLatitudeLongitude({
        latitudeLongitudeForPickup:
          pickupInitialValues?.latitudeLongitude ||
          placeOrderSteperDetails?.CollectionLocation?.LatitudeLongitude,
        latitudeLongitudeForDelivery:
          deliveryInitialValues?.latitudeLongitude ||
          placeOrderSteperDetails?.DeliveryLocation?.LatitudeLongitude,
      })
    );
  }, [pickupInitialValues, deliveryInitialValues]);

  useEffect(() => {
    setDefaultLocation(getDefaultLocation(addresses));
    setLatitudeLongitude({
      latitudeLongitudeForPickup:
        getDefaultLocation(addresses)?.latitudeLongitude,
      latitudeLongitudeForDelivery:
        deliveryInitialValues?.latitudeLongitude || '',
    });
  }, [addresses]);

  useEffect(() => {
    userObjId && dispatch(getAddressList(userObjId));
  }, [userObjId]);

  const favouriteLocations = (addresses, field) => {
    let filteredAddressWithLocation = [];

    for (let i = 0; i < addresses?.length; i++) {
      let favoriteLocationFor =
        field === 'pickup'
          ? addresses[i]?.favoritePickupLocationFor
          : addresses[i]?.favoriteDeliveryLocationFor;

      const filteredLocationOfCurrentUser = favoriteLocationFor?.filter(
        item => item.customerId === currentCustomerId
      );

      if (filteredLocationOfCurrentUser?.length > 0) {
        filteredAddressWithLocation.push(addresses[i]);
      }
    }
    return filteredAddressWithLocation;
  };

  useEffect(() => {
    setFavouriteLocationsObj({
      favouritePickupLocations: favouriteLocations(addresses, 'pickup'),
      favouriteDeliveryLocations: favouriteLocations(addresses, 'delivery'),
    });
  }, [addresses]);

  const swapPickupDeliveryFormValues = () => {
    setDeliveryInitialValues(pickupInitialValues);
    setPickupInitialValues(deliveryInitialValues);
    dispatch(
      setLatitudeLongitude({
        latitudeLongitudeForPickup:
          latitudeLongitude?.latitudeLongitudeForDelivery,
        latitudeLongitudeForDelivery:
          latitudeLongitude?.latitudeLongitudeForPickup,
      })
    );
    dispatch(
      // remove dependncies of states
      setLocationId({
        ...locationId,
        deliveryId: pickupInitialValues?._id,
        pickupId: deliveryInitialValues?._id,
        favoriteDeliveryLocationFor: locationId?.favoritePickupLocationFor,
        favoritePickupLocationFor: locationId?.favoriteDeliveryLocationFor,
      })
    );
    const pickupBtns = document.getElementById('pick');
    const deliveryBtns = document.getElementById('del');

    // hide edit and add Address button on swap location
    if (pickupBtns && deliveryBtns) {
      pickupBtns.style.display = 'none';
      deliveryBtns.style.display = 'none';
    }
    setIsFormEdited(false);
  };

  const conditionalSwapIcon =
    !vnpOrderEntryFields?.CollectionLocation?.IsDisplay ||
    !vnpOrderEntryFields?.DeliveryLocation?.IsDisplay
      ? false
      : true;
  return (
    <>
      {vnpOrderEntryFields && (
        <div
          className='address-details-container'
          style={
            conditionalSwapIcon
              ? { justifyContent: 'space-between' }
              : { justifyContent: 'center' }
          }
        >
          <AddressPopup
            openModel={openModel}
            editMode={editMode}
            initialValues={addressPopupInitialValues}
            setOpenModel={setOpenModel}
            Customer_ontime_id={Customer_ontime_id}
            userObjId={userObjId}
          />

          {(vnpOrderEntryFields?.CollectionLocation?.IsDisplay) && (
            <AddressDetailsPickupDeliveryForm
              key={'pickupForm'}
              form={form}
              isPickup={true}
              Fields={
                vnpOrderEntryFields && vnpOrderEntryFields?.CollectionLocation
              }
              addressCardDetails={addresses}
              favouriteLocations={
                favouriteLocationsObj?.favouritePickupLocations
              }
              setOpenModel={setOpenModel}
              editMode={editMode}
              setEditMode={setEditMode}
              pickupInitialValues={pickupInitialValues}
              deliveryInitialValues={deliveryInitialValues}
              setPickupInitialValues={setPickupInitialValues}
              setDeliveryInitialValues={setDeliveryInitialValues}
              setAddressPopupInitialValues={setAddressPopupInitialValues}
              defaultLocation={defaultLocation}
              setIsFormEdited={setIsFormEdited}
              customerId={userObjId}
            />
          )}

          {conditionalSwapIcon && (
            <div className='swap-icon-wrapper'>
              <img
                src={swapSvg}
                alt=''
                className='swap-icon'
                onClick={swapPickupDeliveryFormValues}
              />
            </div>
          )}

          {vnpOrderEntryFields?.DeliveryLocation?.IsDisplay && (
            <AddressDetailsPickupDeliveryForm
              key={'deliveryForm'}
              form={form}
              isPickup={false}
              addressCardDetails={addresses}
              Fields={
                vnpOrderEntryFields && vnpOrderEntryFields?.DeliveryLocation
              }
              favouriteLocations={
                favouriteLocationsObj?.favouriteDeliveryLocations
              }
              setOpenModel={setOpenModel}
              setEditMode={setEditMode}
              pickupInitialValues={pickupInitialValues}
              deliveryInitialValues={deliveryInitialValues}
              setPickupInitialValues={setPickupInitialValues}
              setDeliveryInitialValues={setDeliveryInitialValues}
              setAddressPopupInitialValues={setAddressPopupInitialValues}
              defaultLocation={defaultLocation}
              setIsFormEdited={setIsFormEdited}
              customerId={userObjId}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AddressDetails;
