import React from 'react';
import MainLayout from '../../Components/Layout/mainLayout';
import AddressBook from '../../Containers/Address/list/addressBook';

const AddressList = () => {
  return (
    <MainLayout>
      <AddressBook />
    </MainLayout>
  );
};

export default AddressList;
