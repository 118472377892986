import { combineReducers } from 'redux';

import AuthSlice from './Auth/AuthSlice';
import spinnerSlice from './Spinner/spinnerSlice';
import AddressSlice from './Address/AddressSlice';
import InvoiceSlice from './Invoice/invoiceSlice';
import OrderSlice from './Order/orderSlice';
import UnsubscribeSlice from './Unsubscrib/unsubscribeSlice';

export default combineReducers({
  AuthSlice,
  spinnerSlice,
  AddressSlice,
  InvoiceSlice,
  OrderSlice,
  UnsubscribeSlice
});
