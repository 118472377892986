import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideSpinner, showSpinner } from '../../Reducers/Spinner/spinnerSlice';
import NGS_API from '../../../Api/ngs';
import Urls from '../../../Constant/urls';
import { checkHttpResponse, openNotification } from '../../../Util/commonUtility';
import { commonError } from '../../../Util/config';
import {
  setInvoiceData,
  setInvoiceList,
} from '../../Reducers/Invoice/invoiceSlice';

export const getInvoicesList = createAsyncThunk(
  'getInvoicesList',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.GET_INVOICE_LIST_API}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setInvoiceList(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
    }
  }
);

export const getInvoicesData = createAsyncThunk(
  'getInvoicesData',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.INVOICE_API}/${id}`);
      dispatch(hideSpinner());

      if (checkHttpResponse(response)) {
        dispatch(setInvoiceData(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
    }
  }
);
