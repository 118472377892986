import React from 'react';
import OrderEntry from '../../Containers/Order/orderEntry/orderEntry';
import MainLayout from '../../Components/Layout/mainLayout';

const OrderEntryScreen = () => {
  return (
    <MainLayout>
      <OrderEntry />
    </MainLayout>
  );
};

export default OrderEntryScreen;
