import React from 'react';
import MainLayout from '../../Components/Layout/mainLayout';
import MyOrder from '../../Containers/Order/list/myOrder';

const MyOrderScreen = () => {
  return (
    <MainLayout>
      <MyOrder />
    </MainLayout>
  );
};

export default MyOrderScreen;
