import { createSlice } from '@reduxjs/toolkit';

const invoiceSlice = createSlice({
  name: 'Invoice',
  initialState: {
    invoices: [],
    invoiceData: {}
  },
  reducers: {
    setInvoiceList(state, { payload }) {
      state.invoices = payload;
    },
    setInvoiceData(state, { payload }) {
      state.invoiceData = payload;
    },
  },
});

export const { setInvoiceList, setInvoiceData } = invoiceSlice.actions;
export default invoiceSlice.reducer;
