import { openNotification } from '../../../Util/commonUtility';
import Urls from '../../../Constant/urls';
import API from '../../../Api/api';
import { hideSpinner, showSpinner } from '../../Reducers/Spinner/spinnerSlice';
import { loginSuccess, loginValidationError } from '../../../Util/config';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginStatus } from '../../Reducers/Auth/AuthSlice';

export const login = createAsyncThunk(
  'login',
  async (value, { dispatch, rejectWithValue }) => {
    const { data, navigate } = value;
    try {
      dispatch(showSpinner());
      const res = await API.put(Urls.AUTH_API_PATH, data);
      dispatch(hideSpinner());

      if (res.status === 200) {
        localStorage.setItem('userAccessToken', res?.data?.payload.token);
        localStorage.setItem('userId', res?.data?.payload.user_id);
        openNotification('success', 'Success', loginSuccess);
        dispatch(loginStatus(true));
        navigate('/order/entry');
      }
    } catch (error) {
      rejectWithValue(error);
      openNotification('error', 'Error', loginValidationError);
      dispatch(hideSpinner());
    }
  }
);
