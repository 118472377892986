import React from 'react';
import './navlinks.scss';
import { Link } from 'react-router-dom';
import {
  orderEntrySvg,
  myOrdersSvg,
  addressBookSvg,
  myInvoiceSvg,
} from '../../../../Resource/images';
import { useSelector } from 'react-redux';
import { findCurrentUserObject } from '../../../../Util/commonUtility';
import { internalRoutes } from '../../../../Constant/internalRoutes';
import { navLinksEnums } from '../../../../Constant/enums';


const Navlinks = ({ onClose }) => {
  const { userData } = useSelector(state => state.AuthSlice); // for icon

  const currentUser = findCurrentUserObject(userData);

  let allNavLinks = [
    {
      svg: orderEntrySvg,
      label: navLinksEnums.orderEntry,
      alt: navLinksEnums.orderEntry,
      linkPath: internalRoutes.ORDER_ENTRY,
    },
    {
      svg: myOrdersSvg,
      label: navLinksEnums.myOrders,
      alt: navLinksEnums.myOrders,
      linkPath: internalRoutes.ORDER_LIST,
    },
  ];

  if (currentUser?.HasAccessToAddressBook) {
    allNavLinks.push({
      svg: addressBookSvg,
      label: navLinksEnums.addressBook,
      alt: navLinksEnums.addressBook,
      linkPath: internalRoutes.ADDRESS_LIST,
    });
  }
  if (currentUser?.isInvoiceActiveContact) {
    allNavLinks.push({
      svg: myInvoiceSvg,
      label: navLinksEnums.myInvoice,
      alt: navLinksEnums.myInvoice,
      linkPath: internalRoutes.INVOICE_LIST,
    });
  }

  return (
    <>
      {allNavLinks.map((item, index) => (
        <Link
          to={item?.linkPath}
          key={index}
          className='router-link'
          onClick={onClose}
        >
          <span className='nav-links-span'>
            <img
              src={item?.svg}
              alt={item?.alt}
              id={item?.label}
              className={`link-svg ${
                item?.label === 'My Invoice' && 'invoice-svg'
              }`}
            />
            <label htmlFor={item?.label}>{item?.label}</label>
          </span>
        </Link>
      ))}
    </>
  );
};

export default Navlinks;
