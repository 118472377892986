import { useNavigate } from 'react-router-dom';
import { ellipse2 } from '../../../../Resource/images';
import './order-info-right.scss';
import { commonConfig } from '../../../../Config/config';

const OrderInfoRight = ({ orderInfoObj }) => {

  const navigate = useNavigate();
  const navigateHandler = () => {
    navigate('/order/list')
  }

  return (
    <div className='info-right'>
      <div className="details-wrapper">
        <h3>Requested by: <span>{orderInfoObj?.requestedBy}</span></h3>
        <div className="details-props">
         <p>Final Cost<span>${orderInfoObj?.totalCost}</span> </p>
         <p>Description <span>{orderInfoObj?.desc}</span> </p>
         <p>Weight <span>{orderInfoObj?.weight} {commonConfig.weightUnit}</span> </p>
         <div className="collect-location">
           Collection Location
           <span>
              {orderInfoObj?.pickCompanyName}<br />
              {orderInfoObj?.pickContactName}<br />
              {orderInfoObj?.pickAddr1}<br />
              {orderInfoObj?.pickCity} {orderInfoObj?.pickPostalCode} 
           </span>
         </div>
         <div className="deliv-location">
          Delivery Location 
           <span>
           {orderInfoObj?.delivCompanyName}<br />
              {orderInfoObj?.delivContactName}<br />
              {orderInfoObj?.delivAddr1}<br />
              {orderInfoObj?.delivCity} {orderInfoObj?.delivPostalCode} 
           </span>
         </div>
         <p>Distance <span>{orderInfoObj?.distance}</span></p>
         </div> 
      </div>
      <button onClick={navigateHandler} >Go Back to order listing</button>
    </div>
  )
}

export default OrderInfoRight