import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../ag-grid.scss';
import { searchHighlight } from '../../../../Services/agGrid/addressListGrid';

const InvoicesGrid = ({ inputRef, data, colDef, setGridApi }) => {
  const cellRenderer = p => {
    return searchHighlight(p, inputRef);
  };

  const onCellClicked = cell => {
    if (cell.value.key === 'invoice-number') {
      window.open(`view/${cell.data._id}`, '_blank');
    }
  };

  const columns = colDef.map(col => {
    return {
      field: col,
      filter: true,
      resizable: true,
      unSortIcon: false,
      cellRenderer: cellRenderer,
    };
  });

  const onGridReady = params => {
    setGridApi(params.api);
  };

  const gridRowStyle = (params) => {
    if (params.data['Payment Status']?.props?.children  === 'UnPaid') {
      return { background: 'rgb(255, 99, 99)' };
  }
  }

  return (
    <div className='ag-theme-alpine' style={{ height: '100%' }}>
      <AgGridReact
        rowHeight={'25'}
        headerHeight={'30'}
        rowData={data}
        columnDefs={columns}
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        tooltipMouseTrack={true}
        onGridReady={onGridReady}
        onCellClicked={onCellClicked}
        defaultColDef={{ sortable: true }}
        pagination={true}
        getRowStyle={gridRowStyle}
      />
    </div>
  );
};

export default InvoicesGrid;
