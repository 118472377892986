import { createAsyncThunk } from '@reduxjs/toolkit';
import Urls from '../../../Constant/urls';
import { hideSpinner, showSpinner } from '../../Reducers/Spinner/spinnerSlice';
import {
  checkHttpResponse,
  getCurrentUserId,
  openNotification,
} from '../../../Util/commonUtility';
import {
  commonError,
  errorUpdateAddress,
  successEditAddress,
  successAddAddress,
  successfullyUpdatedFavouriteAddress,
  addressDeletedSuccessMessage,
  errorInDeletingAddressMessage,
} from '../../../Util/config';
import { addressList } from '../../Reducers/Address/AddressSlice';
import NGS_API from '../../../Api/ngs';

export const getAddressList = createAsyncThunk(
  'getAddressList',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ADDRESS_LIST_API}/${data}`);
      dispatch(hideSpinner());

      if (checkHttpResponse(response)) {
        dispatch(addressList(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const deleteAddress = createAsyncThunk(
  'deleteAddress',
  async ({ id, userObjId }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.delete(
        `${Urls.COMMON_API_PATH_ADDRESS}/${id}`
      );
      dispatch(getAddressList(userObjId));
      dispatch(hideSpinner());

      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', addressDeletedSuccessMessage);
      }
    } catch (error) {
      openNotification('error', 'Error', errorInDeletingAddressMessage);
      dispatch(hideSpinner());
    }
  }
);

export const editAddress = createAsyncThunk(
  'editAddress',
  async (data, { dispatch }) => {
    const { editAddressObjMasking, setOpenModel } = data;
    const { customerId, addressId } = editAddressObjMasking;
    try {
      dispatch(showSpinner());
      const response = await NGS_API.put(
        `${Urls.COMMON_API_PATH_ADDRESS}/${addressId}`,
        editAddressObjMasking
      );
      dispatch(getAddressList(customerId));
      dispatch(hideSpinner());

      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', successEditAddress);
        setOpenModel(false);
      }else{
        openNotification('error', 'Error', errorUpdateAddress);
      }
    } catch (error) {
      openNotification('error', 'Error', errorUpdateAddress);
      dispatch(hideSpinner());
    }
  }
);

export const addAddress = createAsyncThunk(
  'addAddress',
  async (data, { dispatch }) => {
    const { addAddressObjMasking, setOpenModel } = data;
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(`${Urls.COMMON_API_PATH_ADDRESS}`, {
        ...addAddressObjMasking,
      });
      dispatch(getAddressList(addAddressObjMasking.customerId));
      dispatch(hideSpinner());

      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', successAddAddress);
        setOpenModel(false);
      }else{
        openNotification('error', 'Error', errorUpdateAddress);
      }
    } catch (error) {
      // openNotification('error', 'Error', errorUpdateAddress);
      dispatch(hideSpinner());
    }
  }
);

export const addFavouriteLocation = createAsyncThunk(
  'addFavouriteLocation',
  async (data, { dispatch }) => {
    const { addressId, bool, userObjId, type } = data;
    const customerId = getCurrentUserId();
    try {
      const response = await NGS_API.patch(
        `${
          Urls.FAVOURITE_lOCATION_API
        }/${customerId}/${addressId}/${type}/${bool}`,
        {}
      );
      dispatch(getAddressList(userObjId));
      if (checkHttpResponse(response)) {
        openNotification(
          'success',
          'Success',
          successfullyUpdatedFavouriteAddress
        );
      }
    } catch (error) {
      openNotification('error', 'Error', errorUpdateAddress);
    }
  }
);

export const editAndSaveAddress = createAsyncThunk(
  'editAndAddAddress',
  async ({ id, data, type }, { dispatch }) => {
    const { customerId } = data;
    try {
      if (type === 'edit') {
        await NGS_API.put(
          // edit address
          `${Urls.COMMON_API_PATH_ADDRESS}/${id}`,
          data
        );
      } else {
        await NGS_API.post(`${Urls.COMMON_API_PATH_ADDRESS}`, {
          // add address
          ...data,
        });
      }
      dispatch(getAddressList(customerId));
    } catch (error) {
      openNotification('error', 'Error', errorUpdateAddress);
    }
  }
);
