import React from 'react';
import './address-card.scss';
import {
  editSvg,
} from '../../../../../Resource/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  addFavouriteLocation,
} from '../../../../../Store/Action/address/addressAction';

const AddressCard = ({
  addr,
  setOpenModel,
  setEditMode,
  isPickup,
  setPickupInitialValues,
  setDeliveryInitialValues,
  setAddressPopupInitialValues,
}) => {
  const dispatch = useDispatch();
  const favLocationObj = {
    _id: addr?._id,
    favPickup: addr?.favoritePickupLocationFor.length > 0 ? true : false,
    favDelivery: addr?.favoriteDeliveryLocationFor.length > 0 ? true : false,
    defaultLocation: addr?.defaultLocationFor.length > 0 ? true : false,
  };

  const renderAddressCardAsFavoriteLocation = () => {
    if (isPickup) {
      return favLocationObj.favPickup ? '#9FC960' : 'white'
    }
    return favLocationObj.favDelivery ? '#9FC960' : 'white'
  }

  const userDetails = useSelector(state => state.AuthSlice);
  const { userObjId } = userDetails;
  const editAddressHandler = () => {
    setOpenModel(true);
    setEditMode(true);

    const initialValuesMaskedObj = {
      _id: addr?._id,
      'Company Name': addr?.companyName,
      'Contact Name': addr?.contactName,
      Address: addr?.addressLine1,
      'Address Line 2': addr?.addressLine2,
      Comments: addr?.contactName,
      City: addr?.city,
      'Postal Code': addr?.postalCode,
      Email: addr?.email,
      Phone: addr?.phone,
      favPickup:
        addr?.favoritePickupLocationFor.length > 0
          ? addr?.favoritePickupLocationFor
          : false,
      favDelivery:
        addr?.favoriteDeliveryLocationFor.length > 0
          ? addr?.favoriteDeliveryLocationFor
          : false,
      defaultLocation:
        addr?.defaultLocationFor.length > 0 ? addr?.defaultLocationFor : false,
      latitudeLongitude: addr?.latitudeLongitude,
    };
    setAddressPopupInitialValues(initialValuesMaskedObj);
  };
  const setFormFieldValues = () => {
    isPickup ? setPickupInitialValues(addr) : setDeliveryInitialValues(addr);
  };

  const addAsFavouriteLocation = () => {
    isPickup
      ? dispatch(
          addFavouriteLocation({
            addressId: favLocationObj?._id,
            bool: !favLocationObj?.favPickup,
            type: 'pickup',
            userObjId,
          })
        )
      : dispatch(
          addFavouriteLocation({
            addressId: favLocationObj?._id,
            bool: !favLocationObj?.favDelivery,
            type: 'delivery',
            userObjId,
          })
        );
  };

  return (
    <div className='address-card' onClick={setFormFieldValues}>
      <div className='address-details'>
        <div className='details-wrapper'>
          <span>CONTACT NAME</span>
          <p>{addr?.contactName}</p>
        </div>
        <div className='details-wrapper'>
          <span>COMPANY NAME</span>
          <p>{addr?.companyName}</p>
        </div>
        <div className='details-wrapper'>
          <span>ADDRESS LINE 1</span>
          <p>{addr?.addressLine1}</p>
        </div>
      </div>
      <div className='address-action'>
        <span>ACTION</span>
        <div className='action-btns'>
          <button onClick={addAsFavouriteLocation}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              // className={!favLocationObj.favPickup && 'add-hover-effect'}
              viewBox='0 0 24 24'
              strokeWidth='{2}'
              stroke={renderAddressCardAsFavoriteLocation()}
              fill={renderAddressCardAsFavoriteLocation()}
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
              <path d='M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z'></path>
            </svg>
          </button>
          <button onClick={editAddressHandler}>
            <img src={editSvg} alt='' width={18} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
