import React from 'react';
import { Spin } from 'antd';
import './spinner.scss';
import { useSelector } from 'react-redux';

const Spinner = () => {
  const { spinner } = useSelector(state => state.spinnerSlice);

  return (
    <div className={spinner ? 'spinner-wrapper' : 'hide-spinner'}>
      <Spin size='large' />
    </div>
  );
};

export default Spinner;
