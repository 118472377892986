import React from 'react'
import MainLayout from '../../Components/Layout/mainLayout'
import Invoices from '../../Containers/Invoices/list/invoices';

const InvoicesScreen = () => {
  return (
    <MainLayout>
      <Invoices />
    </MainLayout>
  )
}

export default InvoicesScreen;