export const internalRoutes = {
  HOME: '/',
  LOGIN: '/login',
  ADDRESS_LIST: '/address/list',
  INVOICE_LIST: '/invoice/list',
  INVOICE_VIEW: '/invoice/view/:id',
  ORDER_ENTRY: '/order/entry',
  ORDER_ENTRY_WITH_ID: '/order/entry/:id',
  ORDER_LIST: '/order/list',
  ORDER_INFO_WITH_ID: '/order/info/:id',
  ORDER_REPORT: '/order/report/:orderType/:orderId',
  ORDER_TRACKING: '/order/tracking',
  UNSUBSCRIBE: '/unsubscribe',
  NOT_FOUND: '*',
};
