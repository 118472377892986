import React from 'react';
import MainLayout from '../../Components/Layout/mainLayout';
import OrderInfo from '../../Containers/Order/info/orderInfo';

const OrderInfoScreen = () => {
  return (
    <MainLayout>
      <OrderInfo />
    </MainLayout>
  );
};

export default OrderInfoScreen;
