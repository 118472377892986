import React, { useEffect, useState } from 'react';
import './navbar.scss';
import { loginLogo, logOutSvg, menuSvg } from '../../../Resource/images';
import { useNavigate } from 'react-router-dom';
import { cheakIsUserLogin, logout, reloadPage } from '../../../Util/commonUtility';
import SliderMenu from './sliderMenu/SliderMenu';
import Navlinks from './navlinks/navlinks';
import { useDispatch, useSelector } from 'react-redux';
import { setMemorizeFieldsValues } from '../../../Store/Reducers/Address/AddressSlice';

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [isSlider, setIsSlider] = useState(false);
  const { userData } = useSelector(state => state.AuthSlice);

  const logoutHandler = () => {
    dispatch(setMemorizeFieldsValues(null))
    logout(navigate, dispatch);
  };

  const sliderHandler = () => {
    setIsSlider(!isSlider);
  };
  const handleDocumentClick = (e) => {
    const sliderContainer = document.querySelector('.slider-container');
    e.stopPropagation();
    // Check if the clicked element is outside the menu container
    if (
      e.target.classList.contains('menu-svg')
    ) {
      return;
    }
    if (
      isSlider &&
      sliderContainer &&
      !sliderContainer.contains(e.target)
    ) {
      setIsSlider(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isSlider]);

  return (
    <>
      <nav className='navbar'>
        <SliderMenu isSlider={isSlider} setIsSlider={setIsSlider} onClose={() => setIsSlider(false)} />
        <div className='nav-links-wrapper'>
          <img
            src={menuSvg}
            alt=''
            className='menu-svg'
            onClick={sliderHandler}
          />
          <img
            src={loginLogo}
            alt=''
            className='nav-logo'
            onClick={reloadPage}
          />
          <div className='nav-links'>
            <Navlinks setIsSlider={setIsSlider} onClose={() => setIsSlider(false)}/>
          </div>
        </div>
        <button className='nav-logout-btn' onClick={logoutHandler}>
          <div className='profile-name'>{userData?.Name}</div>
          <div className='logout-text-svg'>
            Logout <img src={logOutSvg} alt='' />
          </div>
        </button>
      </nav>
    </>
  );
};

export default Navbar;
