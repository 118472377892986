import React, { useEffect, useState } from 'react';

const PickupDeliveryTemplate = ({ flag, trackingDetails }) => {
  const [isPickup] = useState(flag);

  const [trackingData, setTrackingData] = useState({});

  useEffect(() => {
    setTrackingData(flag ? trackingDetails.pickupFrom : trackingDetails.deliveryTo)
  }, [trackingDetails]);
  return (
    <>
      <h2>{isPickup ? 'Pickup From' : 'Delivery To'}</h2>
      <div className='pickup-details-wrapper'>
        <div className='details'>
          <p>Company Name:</p>
          <span>{trackingData?.companyName}</span>
        </div>
        <div className='details'>
          <p>Contact Name:</p>
          <span>{trackingData?.contactName}</span>
        </div>
        <div className='details'>
          <p>Address:</p>
          <div className='details-wrapper'>
            <span>{trackingData?.AddressLine1}</span>
            {trackingData?.AddressLine2 && (
              <>
                <span>{trackingData?.AddressLine2}</span>
              </>
            )}
            <span>{trackingData?.city}</span>
            <span>{trackingData?.postalCode}</span>
          </div>
        </div>
        <div className='details'>
          <p>Phone No:</p>
          <span>{trackingData?.phone}</span>
        </div>
        <div className='details'>
          <p>{isPickup ? 'Collected From:' : 'Received By: '} </p>
          <span>{trackingData?.from}</span>
        </div>
        <div className='details'>
          <p>Signature: </p>
          <div className='signature-wrapper'>
          {trackingData?.signature ? (
            <img
              src={`data:image/jpg;base64,${trackingData?.signature}`}
              alt=''
              className='signature-style'
            />
          ) : (
            <div className='not-found'>N/A</div>
          )}
        </div>
        </div>
      </div>
    </>
  );
};

export default PickupDeliveryTemplate;
