import React, { useEffect, useState } from 'react';
import './tracking-link-modal.scss';
import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useDispatch } from 'react-redux';
import { sendTrackingLink } from '../../../Store/Action/order/orderAction';
import { commonConfig } from '../../../Config/config';

const TrackingLinkModel = ({
  openModel,
  setOpenModel,
  orderData,
  customerInfo,
}) => {
  const handleOk = () => {};
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const orderInfo = {
    id: orderData?.OnTime360Object?.ID,
    name: orderData?.DeliveryLocation?.CompanyName,
    email: orderData?.DeliveryLocation?.Email,
    trackingNumber: orderData?.OnTime360Object?.TrackingNumber,
  };

  useEffect(() => {
    form.setFieldsValue({
      email: orderData.DeliveryLocation?.Email || orderInfo.email,
    });
    setEmail(orderInfo?.email)
  }, [orderData, openModel]);

  const [extraNotesHear, setExtraNotesHear] = useState('');
  const [email, setEmail] = useState('');
  const sendTrackingLinkHandler = () => {
    const payload = {
      ExtraNotesHere: extraNotesHear,
      customerInfo,
      lblDeliveryEmail: email,
      lblDeliveryName: orderInfo.name,
      trackingNumber: orderInfo.trackingNumber,
    };
    dispatch(sendTrackingLink(payload));
  };

  const trackingOrderHandler = () => {
    window.open(`/order/tracking?TrackingNumber=${orderInfo.trackingNumber}`);
  };

  const onCancel = () => {
    setOpenModel(false)
    setExtraNotesHear('')
    form.resetFields(['emailNote'])
  }

  return (
    <div className='tracking-link-model'>
      <Modal
        title={'Send Tracking Email'}
        forceRender
        open={openModel}
        onOk={handleOk}
        className='tracking-link-modal'
        onCancel={onCancel}
      >
        <Form
          className='tracking-form'
          onFinish={sendTrackingLinkHandler}
          form={form}
        >
          <label
            htmlFor='email'
            className='email-label-class'
            title='Email Note'
          >
            Email Address
          </label>
          <div className='input-wrapper'>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'Please enter your email !' },
                // {
                //   validator: emailValidator,
                //   message: 'please enter valid email',
                // },
              ]}
            >
              <Input
                type='email'
                id='email'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Item>
            <button type='submit'>Send Link</button>
          </div>
          <Form.Item name='emailNote' label='Email Note'>
            <TextArea
              className='text-box-area'
              type='text'
              onChange={e => setExtraNotesHear(e.target.value)}
            />
          </Form.Item>
        </Form>
        <div className='tracking-template'>
          <main className='template-txt'>
            <h3>Hi {orderInfo.name},</h3>
            <p>{extraNotesHear}</p>
            <p>
              You can track your order using this tracking Link:&nbsp;{' '}
              <button onClick={trackingOrderHandler}>Track Now</button>
            </p>
            <div className='regards'>
              <p>
                Regards, <br /> {'Blob station'}
              </p>
            </div>
            <footer className='tracking-footer'>
              <p>{commonConfig.copyrightText}</p>
            </footer>
          </main>
        </div>
      </Modal>
    </div>
  );
};

export default TrackingLinkModel;
