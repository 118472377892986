import React from 'react';
import './auth.scss';
import { loginLogo } from '../../Resource/images';
import { useDispatch } from 'react-redux';
import LoginForm from '../../Components/Auth/loginForm';

import { login } from '../../Store/Action/auth/authAction';
import { useNavigate } from 'react-router-dom';
import { commonConfig } from '../../Config/config';

const AuthContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submiteHandler = async data => {
    dispatch(login({data, navigate}));
    // route to order list page
  };

  return (
    <div className='auth-container'>
      <div className='auth-left-container'>
        <a href=''>
          <img src={loginLogo} alt={commonConfig.CompanyName} className='vnp-login-logo' />
        </a>
      </div>
      <div className='auth-right-container'>
        <LoginForm submiteHandler={submiteHandler} />
      </div>
    </div>
  );
};

export default AuthContainer;
