import React, { useEffect, useRef, useState } from 'react';
import '../../Order/list/my-order.scss';
import OrderGrid from '../../../Components/Common/AgGrid/orderGrid/orderGrid';
import {
  columnMenuSvg,
  deleteAddressSvg,
  editAddressSvg,
} from '../../../Resource/images';
import { getUser } from '../../../Store/Action/user/userAction';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import {
  addNotesAPi,
  deleteDraft,
  getOrderList,
  setGridColumsMenu,
} from '../../../Store/Action/order/orderAction';
import OrderColumnsMenu from '../../../Components/Order/list/orderColumnsMenu';
import {
  filterColumns,
  filterOrder,
  findCurrentUserObject,
  getCurrentUserId,
  formatDateIn_12Hour,
  toUTCFormat,
} from '../../../Util/commonUtility';
import 'primeicons/primeicons.css';
import {
  setLatitudeLongitude,
  setMemorizeFieldsValues,
} from '../../../Store/Reducers/Address/AddressSlice';
import { commonConfig } from '../../../Config/config';
import { useNavigate } from 'react-router-dom';
import ConfirmModal from '../../../Components/Common/Confirm/confirmModal';
import { orderFilterBtnsENUMS } from '../../../Constant/enums';

const MyOrder = () => {
  const [searchValue, setSearchValue] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [deleteDraftModal, setDeleteDraftModel] = useState(false);
  const [contactModal, setContactModal] = useState(false);
  const [draftIdForDelete, setDraftIdForDelete] = useState('');
  const [notes, setNotes] = useState('');
  const [currentFilterType, setCurrentFilterType] = useState('All Orders');
  const navigate = useNavigate();

  const colObj = {
    TrackingNumber: 'Tracking Number',
    OrderStatus: 'Order Status',
    Quote_carrierName: 'Provider',
    Quote_serviceName: 'Service Type',
    OnTime360ObjectDescription: 'Description',
    Weight: `Weight (${commonConfig.weightUnit})`,
    Quantity: 'Quantity',
    Quote_pickupDate: 'Pickup Time',
    Quote_deliveryDate: 'Delivery Time',
    CreatedDate: 'Date Submitted',
    RequestedBy: 'Requested By',
    Quote_distance: 'Distance',
    Quote_price: 'Total Cost',
    TotalTax: 'Total Tax',
    Gst: 'GST%',
    Qst: 'QST%',
    CollectionLocationCity: 'Pickup City',
    CollectionLocationAddressLine1: 'Pickup AddressLine1',
    CollectionLocationAddressLine2: 'Pickup AddressLine2',
    CollectionLocationCompanyName: 'Pickup CompanyName',
    CollectionLocationContactName: 'Pickup ContactName',
    CollectionLocationCountry: 'Pickup Country',
    CollectionLocationEmail: 'Pickup Email',
    CollectionLocationPhone: 'Pickup Phone',
    CollectionLocationPostalCode: 'Pickup PostalCode',
    CollectionLocationState: 'Pickup State',
    DeliveryLocation: 'Delivery Location',
    DeliveryLocationCity: 'Delivery City',
    DeliveryLocationAddressLine1: 'Delivery AddressLine1',
    DeliveryLocationAddressLine2: 'Delivery AddressLine2',
    DeliveryLocationComments: 'Delivery Comments',
    DeliveryLocationCompanyName: 'Delivery CompanyName',
    DeliveryLocationContactName: 'Delivery ContactName',
    DeliveryLocationCountry: 'Delivery Country',
    DeliveryLocationEmail: 'Delivery Email',
    DeliveryLocationState: 'Delivery State',
    DeliveryLocationPhone: 'Delivery Phone',
    DeliveryLocationPostalCode: 'Delivery PostalCode',
    Edit: 'Edit',
    Delete: 'Delete',
  };

  const { userData, userObjId } = useSelector(state => state.AuthSlice);
  const keysArray = Object.keys(colObj);
  const [selectedColumn, setSelectedColumn] = useState(keysArray);
  const [gridApi, setGridApi] = useState('');
  const inputRef = useRef();
  const dispatch = useDispatch();
  const { orderList } = useSelector(state => state.OrderSlice);
  const [rawData, setRowData] = useState(null);
  const [editOrderModal, setEditOrderModal] = useState(false);
  const [orderIdForNote, setOrderIdForNote] = useState(null);

  const notesErrorRef = useRef(null);

  const maskedOrderListData = orderList?.map(order => {
    return {
      'Tracking Number': order?.OnTime360ObjectTrackingNumber ? (
        <span className='tracking-num-wrapper' key={'tracking-num'}>
          {order?.OnTime360ObjectTrackingNumber}
          <i className='pi pi-eye' style={{ fontSize: '1rem' }}></i>
        </span>
      ) : (
        ''
      ),
      'Order Status': order?.OrderStatus,
      Provider: order?.Quote_provider || 'N/A',
      'Service Type': order?.Quote_serviceName || 'N/A',
      Description: order?.OnTime360ObjectDescription,
      [`Weight (${commonConfig.weightUnit})`]: order?.OnTime360ObjectWeight,
      Quantity: order?.Quantity,
      'Pickup Time':
        order?.Quote_pickupDate && formatDateIn_12Hour(order?.Quote_pickupDate),
      'Pickup Time': formatDateIn_12Hour(order?.Quote_pickupDate),
      'Delivery Time': formatDateIn_12Hour(order?.Quote_deliveryDate),
      'Date Submitted': formatDateIn_12Hour(
        new Date(toUTCFormat(order?.CreatedDate))
      ),
      'Requested By': order?.OnTime360Object?.OnTime360Object?.RequestedBy,
      Distance: order?.Quote_distance,
      'Total Cost': order?.TaxInformation?.OrderAmountWithoutTax || 'N/A',
      'Total Tax': order?.TaxInformation?.TotalTaxAmount || 'N/A',
      'GST%': order?.TaxInformation?.TaxArray[0]?.value || 'N/A',
      'QST%': order?.TaxInformation?.TaxArray[1]?.value || 'N/A',
      'Pickup ContactName': order?.CollectionLocationContactName,
      'Pickup CompanyName': order?.CollectionLocationCompanyName,
      'Pickup AddressLine1': order?.CollectionLocationAddressLine1,
      'Pickup AddressLine2': order?.CollectionLocationAddressLine2,
      'Pickup City': order?.CollectionLocationCity,
      'Pickup State': '',
      'Pickup Comments': order?.CollectionLocationComments,
      'Pickup PostalCode': order?.CollectionLocationPostalCode,
      'Pickup Country': order?.CollectionLocationCountry || '',
      'Pickup Email': order?.CollectionLocationEmail,
      'Pickup Phone': order?.CollectionLocationPhone,
      'Pickup LatLong': order?.CollectionLocationLatitudeLongitude,
      'Delivery Location': '',
      'Delivery ContactName': order?.DeliveryLocationContactName,
      'Delivery CompanyName': order?.DeliveryLocationCompanyName,
      'Delivery AddressLine1': order?.DeliveryLocationAddressLine1,
      'Delivery AddressLine2': order?.DeliveryLocationAddressLine2,
      'Delivery City': order?.DeliveryLocationCity,
      'Delivery State': '',
      'Delivery PostalCode': order?.DeliveryLocationPostalCode,
      'Delivery Comments': order?.DeliveryLocationComments,
      'Delivery Country': order?.DeliveryLocationCountry,
      'Delivery Email': order?.DeliveryLocationEmail,
      'Delivery Phone': order?.DeliveryLocationPhone,
      'Delivery LatLong': order?.DeliveryLocationLatitudeLongitude,
      Packages: order?.Packages,
      Edit: <img src={editAddressSvg} alt='Edit' width={18} key={'edit'} />,
      Delete: (
        <img src={deleteAddressSvg} alt='Delete' width={20} key={'delete'} />
      ),
      _id: order?._id,
    };
  });

  useEffect(() => {
    const filteredOrders = filterOrder(
      maskedOrderListData,
      currentFilterType,
      selectedColumn
    );

    setSelectedColumn(filteredOrders.columns);
    setRowData(filteredOrders?.data);
  }, [orderList]);

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    const userId = getCurrentUserId();
    const gridState =
      localStorage.getItem(`${userId}_ColumnStateSaveOrderList`) || null;
    const userGrid = findCurrentUserObject(userData);
    if (!gridState) {
      if (!userGrid) {
        const maskGrid = Object.keys(colObj).map(col => {
          return { field: col, hide: false };
        });
        setSelectedColumn(filterColumns(maskGrid, 'All Orders'));
      } else {
        if (!userGrid?.ColumnStateSaveOrderList) {
          const defaultColumnState = Object.keys(colObj).map(col => {
            return { field: colObj[col], hide: false };
          });
          const forBack = Object.keys(colObj).map(col => {
            return { colId: col, hide: false };
          });
          localStorage.setItem(
            `${userId}_ColumnStateSaveOrderList`,
            JSON?.stringify(forBack)
          );
          dispatch(setGridColumsMenu({ grid: JSON.stringify(forBack) }));

          setSelectedColumn(filterColumns(defaultColumnState, 'All Orders'));
          return;
        }
        const grid =
          userGrid && JSON?.parse(userGrid?.ColumnStateSaveOrderList);
        const finalGridForLocalStorage = grid?.map(col =>
          JSON?.parse(userGrid?.ColumnStateSaveOrderListShowHide)?.includes(
            col.colId
          )
            ? { ...col, hide: false }
            : { ...col, hide: true }
        );
        const newFinalGrid = finalGridForLocalStorage.map(col => {
          return { field: colObj[col.colId], hide: col.hide };
        });
        finalGridForLocalStorage &&
          localStorage.setItem(
            `${userId}_ColumnStateSaveOrderList`,
            JSON?.stringify(finalGridForLocalStorage)
          );

        setSelectedColumn(filterColumns(newFinalGrid, 'All Orders'));
        return;
      }
    }
    if (userGrid && !userGrid?.ColumnStateSaveOrderList) {
      const defaultColumnState = Object.keys(colObj).map(col => {
        return { field: colObj[col], hide: false };
      });

      const forBack = Object.keys(colObj).map(col => {
        return { colId: col, hide: false };
      });
      dispatch(setGridColumsMenu({ grid: JSON.stringify(forBack) }));
      localStorage.setItem(
        `${userId}_ColumnStateSaveOrderList`,
        JSON?.stringify(forBack)
      );
      setSelectedColumn(filterColumns(defaultColumnState, 'All Orders'));
      return;
    }

    const grid =
      userGrid &&
      JSON?.parse(userGrid?.ColumnStateSaveOrderList)
        ?.map(col =>
          JSON?.parse(userGrid?.ColumnStateSaveOrderListShowHide)?.includes(
            col.colId
          )
            ? { ...col, hide: false }
            : { ...col, hide: true }
        )
        ?.map((col, i) => {
          return { field: colObj[col.colId], hide: col?.hide };
        }); // Todo refector logic;
    setSelectedColumn(filterColumns(grid, 'All Orders'));
  }, [userData]);

  useEffect(() => {
    if (userObjId) {
      dispatch(getOrderList(userObjId));
    }
  }, [userObjId]);

  const searchFilterHandler = e => {
    setSearchValue(e.target.value);
    gridApi?.api?.setQuickFilter(e.target.value);
  };

  const btnNames = [
    orderFilterBtnsENUMS.allOrders,
    orderFilterBtnsENUMS.draft,
    orderFilterBtnsENUMS.submitted,
    orderFilterBtnsENUMS.inTransit,
    orderFilterBtnsENUMS.delivered,
  ];

  const filterOrderHandler = (e, value) => {
    setCurrentFilterType(value);
    const btnsArr = document.getElementsByClassName('filter-btns');

    [...btnsArr].forEach(element => {
      element !== e.target && element.classList.remove('active');
    });
    e.target.classList.add('active');
    const filteredOrders = filterOrder(
      maskedOrderListData,
      value,
      selectedColumn
    );
    setRowData(filteredOrders?.data);
    setSelectedColumn(filteredOrders.columns);
  };

  const confirmDeleteDraft = () => {
    dispatch(deleteDraft({ id: draftIdForDelete, userId: userObjId }));
    setDeleteDraftModel(false);
  };

  const editDraftOrder = (data, type) => {
    const maskedObjForMemo = {
      CompanyName: data['Pickup CompanyName'],
      ContactName: data['Pickup ContactName'],
      AddressLine1: data['Pickup AddressLine1'],
      AddressLine2: data['Pickup AddressLine2'],
      PostalCode: data['Pickup PostalCode'],
      City: data['Pickup City'],
      Phone: data['Pickup Phone'],
      Comments: data['Pickup Comments'],
      Email: data['Pickup Email'],
      LatitudeLongitude: data['Pickup LatLong'],
      CompanyNameForDelivery: data['Delivery CompanyName'],
      ContactNameForDelivery: data['Delivery ContactName'],
      AddressLine1ForDelivery: data['Delivery AddressLine1'],
      AddressLine2ForDelivery: data['Delivery AddressLine2'],
      PostalCodeForDelivery: data['Delivery PostalCode'],
      CityForDelivery: data['Delivery City'],
      PhoneForDelivery: data['Delivery Phone'],
      CommentsForDelivery: data['Delivery Comments'],
      EmailForDelivery: data['Delivery Email'],
      LatitudeLongitudeForDelivery: data['Delivery LatLong'],
      packages: data.Packages,
      orderId: data?._id,
    };
    dispatch(
      setLatitudeLongitude({
        latitudeLongitudeForPickup: data['Pickup LatLong'],
        latitudeLongitudeForDelivery: data['Delivery LatLong'],
      })
    );
    dispatch(setMemorizeFieldsValues({ type, ...maskedObjForMemo }));
    navigate(`/order/entry/${data?._id}`);
  };
  const onCancel = () => {
    setDeleteDraftModel(false);
  };

  const onCancelNotes = () => {
    setContactModal(false)
    notesErrorRef.current.style.display = 'none';
    setNotes('')
  }
  const columnMenuContainer = document.querySelector('.column-menu-container');
  const handleDocumentClick = e => {
    e.stopPropagation();
    // Check if the clicked element is outside the menu container
    if (
      e.target.classList.contains('column-menu-btn') ||
      e.target.classList.contains('column-menu-icon')
    ) {
      return;
    }
    if (
      isMenuOpen &&
      columnMenuContainer &&
      !columnMenuContainer.contains(e.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, false);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [isMenuOpen]);

  const onCellClicked = cell => {
    if (cell.value.key === 'tracking-num') {
      navigate(`/order/info/${cell.data._id}`);
    }
    if (cell.value.key === 'delete') {
      setDraftIdForDelete(cell.data._id);
      setDeleteDraftModel(true);
    }
    if (cell.value.key === 'edit') {
      if (currentFilterType === orderFilterBtnsENUMS.inTransit) {
        setOrderIdForNote(cell?.data?._id);
        setContactModal(true);
        return;
      }
      if (currentFilterType === orderFilterBtnsENUMS.draft) {
        editDraftOrder(cell.data, orderFilterBtnsENUMS.draft);
      }
      if (currentFilterType === orderFilterBtnsENUMS.submitted) {
        editDraftOrder(cell.data, orderFilterBtnsENUMS.submitted);
      }
    }
  };

  const addNotesHandler = () => {
    if (!notes) {
      notesErrorRef.current.style.display = 'block';
      return;
    }
    notesErrorRef.current.style.display = 'none';
    if (notes && orderIdForNote) {
      dispatch(addNotesAPi({ OrderId: orderIdForNote, notesPayload: notes }));
      setContactModal(false)
      setNotes('');
    }
  };

  const NotesModalFooter = (
    <div className='notes-modal-footer'>
      <button onClick={addNotesHandler}>Save</button>
    </div>
  );

  const EditOrderModalFooter = (
    <div className='notes-modal-footer'>
      <button>Ok</button>
    </div>
  );

  const onNotesChange = e => {
    if (e.target.value) {
      notesErrorRef.current.style.display = 'none';
    }
    setNotes(e.target.value);
  };

  return (
    <>
      <ConfirmModal
        open={deleteDraftModal}
        onCancel={onCancel}
        onOk={confirmDeleteDraft}
        confirmTxt={'Do you want to delete this record?'}
      />
      <Modal
        title={'Information'}
        open={editOrderModal}
        onCancel={() => setEditOrderModal(false)}
        className='notes-modal'
        footer={EditOrderModalFooter}
      >
        <div className='edit-order-modal-body'>
          You cannot edit this order. Please contact the admin for assistance.
          <br />
          <span>
            Email : <span className='email'>{commonConfig.vnpEmail}</span>
            <br />
            Contact :{' '}
            <span className='contact-num'>{commonConfig.vnpContactNumber}</span>
          </span>
        </div>
      </Modal>
      <Modal
        title={'Notes'}
        open={contactModal}
        onCancel={onCancelNotes}
        onOk={() => setContactModal(false)}
        className='notes-modal'
        footer={NotesModalFooter}
      >
        <div className='notes-modal-body'>
          <textarea
            onChange={onNotesChange}
            placeholder='Notes here...'
            name=''
            id=''
            cols='30'
            rows='10'
            className='notes-text-area'
            value={notes}
          ></textarea>
          <span
            className='error-txt'
            style={{ display: 'none' }}
            ref={notesErrorRef}
          >
            Please enter notes
          </span>
          <strong className={'intransit-warn-txt'}>
            If you want to Edit this Order Please Contact{' '}
            <span>{commonConfig.vnpContactNumber}</span>
          </strong>
        </div>
      </Modal>
      <div className='order-list-container'>
        <div className='order-list-header'>
          <div className='filter-btns-wrapper'>
            {btnNames.map((name, i) => (
              <button
                key={i}
                className={`filter-btns ${name === 'All Orders' && 'active'}`}
                onClick={e => filterOrderHandler(e, name)}
              >
                {name}
              </button>
            ))}
          </div>
          <div className='input-menu-wrapper'>
            <input
              ref={inputRef}
              onChange={searchFilterHandler}
              type='search'
              placeholder='Search'
              className='order-search-box'
            />
            <button
              className='column-menu-btn'
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <img src={columnMenuSvg} className='column-menu-icon' alt='' />
            </button>
            <OrderColumnsMenu
              setSelectedColumn={setSelectedColumn}
              isMenuOpen={isMenuOpen}
              setIsMenuOpen={setIsMenuOpen}
              colObj={colObj}
              gridApi={gridApi}
              userData={userData}
              currentFilterType={currentFilterType}
            />
          </div>
        </div>
        <div className='order-grid-wrapper'>
          <OrderGrid
            inputRef={inputRef}
            data={rawData ? rawData : maskedOrderListData}
            colDef={selectedColumn}
            setSelectedColumn={setSelectedColumn}
            colObj={colObj}
            setGridApi={setGridApi}
            setDeleteDraftModel={setDeleteDraftModel}
            setDraftIdForDelete={setDraftIdForDelete}
            editDraftOrder={editDraftOrder}
            onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </>
  );
};

export default MyOrder;
