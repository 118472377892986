import React, { useEffect, useState } from 'react';
import './shipment-services.scss';
import { Form, Spin, Switch } from 'antd';
import moment from 'moment';
import { calculatePriceWithTax } from '../../../../Util/commonUtility';
import { LoadingOutlined } from '@ant-design/icons';

const ShipmentServices = ({
  service,
  tax,
  PriceModifies,
  selectShipmentHandler,
  filterePriceModi,
  finalPrice,
  form,
}) => {
  const [filteredPriceModi, setFilteredPriceMod] = useState([]);

  const serviceName = `${service?.service.serviceName}`;
  const dateFormate = 'dddd, MMMM D, y';
  const formatedDate = moment(service?.service.deliveryDate).format(dateFormate);
  useEffect(() => {
    setFilteredPriceMod(filterePriceModi(service.service));
  }, [service]);

  const ischecked = (e, pm) => {
    if (pm.SelectedState === 'Required' || pm.SelectedState === 'Selected') {
      return 'checked';
    }
    return '';
  };

  return (
    <>
      <tr>
        <th>{serviceName}</th>
        <th>{formatedDate}</th>
        <th className='price'>
          ${finalPrice.for === serviceName ? finalPrice.price : service.service?.price}
          CAD
        </th>
        <th>
          <button
            id={service.service?.quoteId}
            onClick={() =>
              selectShipmentHandler(service?.service.quoteId, service, serviceName)
            }
            className={`select-btn ${service.service?.quoteId}-btn`}
          >
            Select
          </button>
        </th>
      </tr>
      <tr
        className={`priceMod-conatiner ${service?.service.quoteId}`}
        style={{ display: 'none' }}
      >
        <td colSpan='4'>
          <div className='dynamic-toggles'>
            <div className='toggles-wrapper'>
              <Form
                form={form}
                className='pm-form'
                style={
                  filteredPriceModi.length <= 0 && {
                    gridTemplateColumns: 'repeat(1, 1fr)',
                  }
                }
              >
                {filteredPriceModi.length <= 0 ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 26,
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  filteredPriceModi?.map((pm, i) => (
                    <div className='toggle' key={i}>
                      <Form.Item name={pm?.Name}>
                        <Switch
                          size='small'
                          defaultChecked={e => ischecked(e, pm)}
                          className='price-switch'
                          id={pm?.Name}
                          disabled={pm.SelectedState === 'Required'}
                          onChange={e => PriceModifies(e, pm?.ID, pm)}
                        />
                      </Form.Item>
                      <label
                        htmlFor={pm?.Name}
                        className={
                          pm.SelectedState === 'Required' && 'disabled'
                        }
                      >
                        {pm?.Name}
                      </label>
                    </div>
                  ))
                )}
              </Form>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ShipmentServices;
