
module.exports = {
  JwtExpiryError: 'Session expired Please relogin',
  loginValidationError: 'Login Failed',
  commonError: 'Something wents Wrong please refresh the page',
  loginSuccess: 'Login Successful',
  successEditAddress: 'Address Successfully Updated',
  successAddAddress: 'Address Successfully Added',
  // successFavouriteAddAddress: 'Address Added in favourite locations',
  successfullyUpdatedFavouriteAddress: 'Favorite location updated successfully',
  errorUpdateAddress: 'Please enter valid Phone Number',
  networkError: 'Please check your internet connection!',
  orderNotFound: 'Order not found with given Tracking Number',
  selectPickupLocation: 'Please select pickup location',
  selectDeliveryLocation: 'Please select delivery location',
  changedFormData: 'you have changed the form data. please save/edit the values first',
  selectQuote: 'Please Select a Quote',
  refetchPriceMessage: 'Please Re-Fetch the price',
  enterTrackingNumberMessage: 'Please enter Tracking Number',
  addressDeletedSuccessMessage: 'Address Successfully Deleted',
  errorInDeletingAddressMessage: 'error in deleting Address',
  emailSentMessage: 'Email has been sent.'
}
