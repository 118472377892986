import { getCurrentUserId } from '../../Util/commonUtility';
import Urls from '../../Constant/urls';
import VNP_API from '../../Api/api';
import reactStringReplace from 'react-string-replace';

export const saveGridState = gridState => {
  const customerId = getCurrentUserId();
  const key = `customer-address-grid-${customerId}`;

  localStorage.setItem(key, JSON.stringify(gridState));
};

export const getGridState = async (params) => {
  const customerId = getCurrentUserId();
const initialGrid = params?.columnApi?.getColumnState()
  const key = `customer-address-grid-${customerId}`;
  const gridData = localStorage.getItem(key) || null;
  if (!gridData) {
    const res = await VNP_API.get(`${Urls.TABLE_STATE_API}/${key}`);
    const resData = res.data?.payload?.table_state;

    if (resData) {
      localStorage.setItem(key, resData);
    }else{
      localStorage.setItem(key, JSON.stringify(initialGrid));
    }
    return resData ? JSON.parse(resData) : initialGrid;
  } else {
    return JSON.parse(gridData);
  }
};
export const searchHighlight = (params, ref) => {
  if (!ref.current?.value) {
    return params.value;
  } else {
    return reactStringReplace(params.value, ref.current.value, (match, i) => (
      <mark key={i}>{match}</mark>
    ));
  }
};
