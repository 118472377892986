import React, { useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../ag-grid.scss';
import { useDispatch } from 'react-redux';
import { getCurrentUserId } from '../../../../Util/commonUtility';
import {
  getGridState,
  saveGridState,
  searchHighlight,
} from '../../../../Services/agGrid/addressListGrid';
import VNP_API from '../../../../Api/api';
import Urls from '../../../../Constant/urls';

const AgGridAddress = ({
  inputRef,
  data,
  colDef,
  handleCellClicked,
  setGridApi,
  gridApi
}) => {
  const cellRenderer = p => {
    return searchHighlight(p, inputRef);
  };
  const columns = colDef.map(col => {
    return {
      field: col,
      filter: col === 'Edit' || col === 'Delete' ? false : true,
      resizable: true,
      unSortIcon: col === 'Edit' || col === 'Delete' ? false : true,
      cellRenderer: cellRenderer,
    };
  });
  const [columnDefs, setColumnDefs] = useState(columns);
  const dispatch = useDispatch();
  const gridRef = useRef();

  const onColumnMoved = async data => {
    if (data?.source === 'uiColumnMoved') {
      const customerId = getCurrentUserId();
      const code = `customer-address-grid-${customerId}`;
      const gridState = await data.columnApi.getColumnState();

      const maskedData = {
        table_code: code,
        table_state: JSON.stringify(gridState),
      };
      await VNP_API.put(`${Urls.TABLE_STATE_API}`, maskedData);
      saveGridState(gridState);
    }
  };

  const onFirstDataRendered = async params => {
    const tableColumnState = await getGridState(params);

    params.columnApi.applyColumnState({
      state: tableColumnState,
      applyOrder: true,
    });
  };

  const onGridReady = params => {
    setGridApi(params.api);
  };

  return (
    <div className='ag-theme-alpine' style={{ height: '100%' }}>
      <AgGridReact
        className='address-list-grid'
        ref={gridRef}
        rowData={data}
        rowHeight={'25'}
        headerHeight={'30'}
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        tooltipMouseTrack={true}
        onGridReady={onGridReady}
        defaultColDef={{ sortable: true }}
        columnDefs={columnDefs}
        pagination={true}
        onCellClicked={handleCellClicked}
        onColumnMoved={async e => await onColumnMoved(e)}
        onFirstDataRendered={async e => {
          await onFirstDataRendered(e);
        }}
      />
    </div>
  );
};

export default AgGridAddress;
