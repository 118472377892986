import { createSlice } from '@reduxjs/toolkit';

export const orderSlice = createSlice({
  name: 'Order',
  initialState: {
    orderList: [],
    orderInfo: {},
    deliverySignatures: null,
    collectionSignatures: null,
    ordersColumnList: [],
    ordersTrackingDetails: {},
    orderVisibleGrid: {},
    orderMenuGridList: [],
    packages: [],
    maskedPackages: [],
    packageDescription: '',
    packagesIdArrayInStore: [],
    quoteData: [],
    showQuote: false,
    settings: {},
    report: null,
    attachments: '',
    recalculatedPrice: '',
    quoteSubmitted: {},
    editDraftOrderValues: {}
  },
  reducers: {
    setOrderList(state, { payload }) {
      state.orderList = payload;
    },
    setOrderInfo(state, { payload }) {
      state.orderInfo = payload;
    },
    setDelivSignatures(state, { payload }) {
      state.deliverySignatures = payload;
    },
    setColSignatures(state, { payload }) {
      state.collectionSignatures = payload;
    },
    setAttachments(state, { payload }) {
      state.attachments = payload;
    },
    setReport(state, { payload }) {
      state.report = payload;
    },
    setOrdersColumnList(state, { payload }) {
      state.ordersColumnList = payload;
    },
    setOrdersTrackingDetails(state, { payload }) {
      state.ordersTrackingDetails = payload;
    },
    setOrderVisibleGrid(state, { payload }) {
      state.orderVisibleGrid = payload;
    },
    setOrderMenuGridList(state, { payload }) {
      state.orderMenuGridList = payload;
    },
    setPackages(state, { payload }) {
      state.packages = payload;
    },
    setPackagesIdArrayInStore(state, { payload }) {
      state.packagesIdArrayInStore = payload;
    },
    setQuoteData(state, { payload }) {
      state.quoteData = payload;
    },
    setSetting(state, { payload }) {
      state.settings = payload;
    },
    setMaskedPackages(state, { payload }) {
      state.maskedPackages = payload;
    },
    setPackageDescription(state, { payload }) {
      state.packageDescription = payload;
    },
    setRecalculatedPrice(state, { payload }) {
      state.recalculatedPrice = payload;
    },
    setQuoteSubmitted(state, { payload }) {
      state.quoteSubmitted = payload;
    },
    setShowQuote(state, { payload }) {
      state.showQuote = payload;
    },
    setEditDraftOrderValues(state, { payload }) {
      state.editDraftOrderValues = payload;
    },
  },
});

export const {
  setOrderList,
  setOrderInfo,
  setDelivSignatures,
  setColSignatures,
  setAttachments,
  setReport,
  setOrdersTrackingDetails,
  setOrderMenuGridList,
  setPackages,
  setPackagesIdArrayInStore,
  setQuoteData,
  setSetting,
  setMaskedPackages,
  setPackageDescription,
  setRecalculatedPrice,
  setQuoteSubmitted,
  setShowQuote,
  setOrderVisibleGrid,
  setEditDraftOrderValues
} = orderSlice.actions;

export default orderSlice.reducer;
