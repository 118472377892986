import { createAsyncThunk } from '@reduxjs/toolkit';
import { hideAttchmentsLoader, hideSpinner, showAttchmentsLoader, showSpinner } from '../../Reducers/Spinner/spinnerSlice';
import {
  calculatePriceWithTax,
  checkHttpResponse,
  checkHttpStatusCode,
  getCurrentUserId,
  openNotification,
} from '../../../Util/commonUtility';
import { commonError, emailSentMessage, orderNotFound } from '../../../Util/config';
import NGS_API from '../../../Api/ngs';
import Urls from '../../../Constant/urls';
import {
  setAttachments,
  setColSignatures,
  setDelivSignatures,
  setOrderInfo,
  setOrderList,
  setOrderVisibleGrid,
  setOrdersTrackingDetails,
  setPackages,
  setQuoteData,
  setQuoteSubmitted,
  setRecalculatedPrice,
  setReport,
  setSetting,
  setShowQuote,
} from '../../Reducers/Order/orderSlice';
import VNP_API from '../../../Api/api';
import { setMemorizeFieldsValues } from '../../Reducers/Address/AddressSlice';

export const getOrderList = createAsyncThunk(
  'getOrders',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_API}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrderList(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const getOrderInfo = createAsyncThunk(
  'getOrderInfo',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_INFO_API}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrderInfo(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const getOrderSignatures = createAsyncThunk(
  'getOrderSignatures',
  async ({ id, type }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(
        `${Urls.ORDER_SIGNATURE_API}/${id}/${type}`
      );
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        if (type === 1) {
          dispatch(setDelivSignatures(response.data.data));
        } else {
          dispatch(setColSignatures(response.data.data));
        }
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const getOrderAttachments = createAsyncThunk(
  'getOrderAttachments',
  async (id, { dispatch }) => {
    try {
      dispatch(showAttchmentsLoader());
      const response = await NGS_API.get(`${Urls.ORDER_ATTACHMENTS_API}/${id}`);
      dispatch(hideAttchmentsLoader());

      if (checkHttpResponse(response)) {
        dispatch(setAttachments(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideAttchmentsLoader());
    }
  }
);

export const getOrderReport = createAsyncThunk(
  'getOrderReport',
  async (data, { dispatch }) => {
    const { url } = data;
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_REPORT_API}/${url}`, {
        responseType: 'arraybuffer',
      });
      let blobUrl = URL.createObjectURL(new Blob([response?.data]));

      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setReport(blobUrl));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const sendTrackingLink = createAsyncThunk(
  'sendTrackingLink',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await VNP_API.post(
        `${Urls.SEND_TRACKING_LINK_API}`,
        data
      );
      dispatch(hideSpinner());

      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', emailSentMessage);
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const downloadReport = createAsyncThunk(
  'download',
  async (url, { dispatch }) => {
    try {
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'blob.pdf');
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      openNotification('error', 'Error', commonError);
    }
  }
);

export const getOrderTrackingDetails = createAsyncThunk(
  'getOrderTRackingDetails',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.ORDER_TRACKING_API}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrdersTrackingDetails(response.data.data));
      }else{
        dispatch(setOrdersTrackingDetails(null));
        openNotification('error', 'Error', orderNotFound);
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const setGridColumsMenu = createAsyncThunk(
  'setGridColumsMenu',
  async (data, { dispatch }) => {
    const customerId = getCurrentUserId();
    const { grid } = data;
    const maskedData = {
      ColumnStateSaveOrderList: grid,
      ContactId: customerId,
    };

    try {
      const response = await VNP_API.put(
        `${Urls.ORDER_COLUMN_STATE_API}/${customerId}`,
        maskedData
      );
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrderVisibleGrid(response?.data?.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
    }
  }
);

export const setGridColumsVisibility = createAsyncThunk(
  'setGridColumsVisibility',
  async (data, { dispatch }) => {
    const customerId = getCurrentUserId();
    const { grid } = data;
    const payload = {
      ContactId: customerId,
      selectedColumnGridOrder: grid
    };
    try {
      const response = await VNP_API.put(
        `${Urls.ORDER_COLUMN_VISIBLE_API}/${customerId}`,
        payload
      );
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrderVisibleGrid(response?.data?.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
    }
  }
);

export const getPackages = createAsyncThunk(
  'getPackages',
  async (_, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await VNP_API.get(`${Urls.GET_PACKAGES_API}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setPackages(response.data.payload));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const saveOrderAsDraft = createAsyncThunk(
  'saveOrderAsDraft',
  async (data, { dispatch }) => {
    const { formData, bool, userObjId } = data;
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(
        `${Urls.SAVE_DRAFT_API}/${bool}`,
        formData
      );
      dispatch(hideSpinner());
      if (response.status === 200) {
        openNotification('success', 'Success', 'Order has been Drafted');
          dispatch(getOrderList(userObjId))
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const quoteApi = createAsyncThunk(
  'quoteApi',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(`${Urls.QUOTE_API}`, data);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setQuoteData(response?.data?.data));
      } else {
        dispatch(setShowQuote(true));
      }
    } catch (error) {
      dispatch(hideSpinner());
    }
  }
);

export const getSettings = createAsyncThunk(
  'getSettings',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await VNP_API.get(`${Urls.GET_SETTINGS_API}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setSetting(response.data.payload));
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const recalculatePriceMod = createAsyncThunk(
  'recalculatePrice',
  async (data, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(
        `${Urls.REFRESH_PRICE_API}`,
        data?.data
      );
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setRecalculatedPrice(response?.data?.data));
        openNotification(
          'success',
          'Success',
          `Price Fetched, New Price! ${
            calculatePriceWithTax(data?.tax, response?.data?.data, '')?.price
          }`
        );
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const submitQuote = createAsyncThunk(
  'submiteQuote',
  async (data, { dispatch }) => {
    const { payload, bool } = data;
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(
        `${Urls.SAVE_DRAFT_API}/${bool}`,
        payload
      );
      dispatch(hideSpinner());
      if (checkHttpStatusCode(response)) {
        dispatch(setQuoteSubmitted(response?.data?.data));
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const deleteDraft = createAsyncThunk(
  'deleteDraft',
  async ({ id, userId }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.delete(`${Urls.ORDER_API}/${id}/Draft`);
      if (checkHttpStatusCode(response)) {
        dispatch(getOrderList(userId));
      }
      dispatch(hideSpinner());
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const placeOrderApi = createAsyncThunk(
  'placeOrder',
  async ({ data, navigate }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(`${Urls.ORDER_API}`, data);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', 'Order Placed');
        navigate(`/order/info/${response?.data?.data?._id}`);
        dispatch(setMemorizeFieldsValues(null))
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const editOrderApi = createAsyncThunk(
  'placeOrder',
  async ({ data, navigate }, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.post(`${Urls.ORDER_EDIT_API}/${data?.OrderId}`, data);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        openNotification('success', 'Success', 'Order Updated');
        navigate(`/order/info/${response?.data?.data?._id}`);
      }
    } catch (error) {
      openNotification('error', 'Error', 'Something Went Wrong');
      dispatch(hideSpinner());
    }
  }
);

export const refreshOrderFromOnTime = createAsyncThunk(
  'getOrderInfo',
  async (id, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.get(`${Urls.REFRESH_ORDER_FROM_ONTIME_SYSTEM}/${id}`);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setOrderInfo(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

export const addNotesAPi = createAsyncThunk(
  'getOrderInfo',
  async ({OrderId, notesPayload}, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await NGS_API.put(`${Urls.ADD_NOTES_API}/${OrderId}`, {Notes: notesPayload});
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        // dispatch(setOrderInfo(response.data.data));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
      dispatch(hideSpinner());
    }
  }
);

