import React, { useEffect, useState } from 'react';
import './order-tracking.scss';
import { Steps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartTrackingSvg,
  deliveryTackingSvg,
  inProdTrackingSvg,
  shipmentTrackingSvg,
} from '../../../Resource/images';
import TrackingDetails from '../../../Components/Order/tracking/trackingDetails';
import {
  getOrderAttachments,
  getOrderSignatures,
  getOrderTrackingDetails,
} from '../../../Store/Action/order/orderAction';
import {
  downloadAttachmentUtils,
  formatDateIn_12Hour,
  openNotification,
  trackingOrderStatusWithTime,
} from '../../../Util/commonUtility';
import { enterTrackingNumberMessage } from '../../../Util/config';
import { commonConfig } from '../../../Config/config';

const OrderTracking = () => {
  const dispatch = useDispatch();
  const { ordersTrackingDetails: trackingData } = useSelector(
    state => state.OrderSlice
  );
  const { attachments, deliverySignatures, collectionSignatures } = useSelector(
    state => state.OrderSlice
  );
  const [trackingNumber, setTrackingNumber] = useState('');

  const queryParameters = new URLSearchParams(window.location.search);
  const trackingNumFromUrl = queryParameters.get('TrackingNumber');

  useEffect(() => {
    setTrackingNumber(trackingNumFromUrl);
    dispatch(getOrderTrackingDetails(trackingNumFromUrl));
  }, []);

  useEffect(() => {
    const orderId = trackingData?.OnTime360Object?.ID;
    if (orderId) {
      dispatch(getOrderAttachments(orderId));
      dispatch(getOrderSignatures({ id: orderId, type: 0 }));
      dispatch(getOrderSignatures({ id: orderId, type: 1 }));
    }
  }, [trackingData])

  const customDot = dot => <>{dot}</>;
  const [openDetails, setOpenDetails] = useState(false);
  const orderTrackingTime =
    trackingData && trackingOrderStatusWithTime(trackingData?.OrderStatusArray);

  const items = [
    {
      title: 'In Production',
      description:
        (orderTrackingTime?.submittedDate &&
          formatDateIn_12Hour(new Date(orderTrackingTime?.submittedDate))) ||
        '',
    },
    {
      title: 'Ready for Pickup',
      description:
        (orderTrackingTime?.inProductionDate &&
          formatDateIn_12Hour(new Date(orderTrackingTime?.inProductionDate))) ||
        '',
    },
    {
      title: 'Order En Route',
      description:
        (orderTrackingTime?.inTransitDate &&
          formatDateIn_12Hour(new Date(orderTrackingTime?.inTransitDate))) ||
        '',
    },
    {
      title: 'Order Delivered',
      description:
        (orderTrackingTime?.deliveryDate &&
          formatDateIn_12Hour(new Date(orderTrackingTime?.deliveryDate))) ||
        '',
    },
  ];

  const trackingRouteStatus = status => {
    switch (status) {
      case 'Submitted':
        return 0;
      case 'Ready for Pickup':
        return 1;
      case 'In-Transit':
        return 2;
      case 'Delivered':
        return 3;
      default:
        return 0;
    }
  };

  const calculateWeight = trackingData?.Packages?.reduce(
    (total, pkg) => total + pkg?.Weight,
    0
  );

  const orderTrackingDetailsObj = {
    trackingNum: trackingData?.OnTime360Object?.TrackingNumber,
    serviceType: trackingData?.Quote?.serviceName,
    orderStatus: trackingData?.OrderStatus,
    deliveryTime: formatDateIn_12Hour(new Date(trackingData?.CreatedDate)),
    trackingRouteState:
      trackingData?.OrderStatusArray &&
      trackingData?.OrderStatusArray[0]?.status,
    pickupFrom: {
      companyName: trackingData?.CollectionLocation?.CompanyName,
      contactName: trackingData?.CollectionLocation?.ContactName,
      AddressLine1: trackingData?.CollectionLocation?.AddressLine1,
      city: trackingData?.CollectionLocation?.City,
      postalCode: trackingData?.CollectionLocation?.PostalCode,
      phone: trackingData?.CollectionLocation?.Phone,
      from: '',
      signature: collectionSignatures,
    },
    deliveryTo: {
      companyName: trackingData?.DeliveryLocation?.CompanyName,
      contactName: trackingData?.DeliveryLocation?.ContactName,
      AddressLine1: trackingData?.DeliveryLocation?.AddressLine1,
      city: trackingData?.DeliveryLocation?.City,
      postalCode: trackingData?.DeliveryLocation?.PostalCode,
      phone: trackingData?.DeliveryLocation?.Phone,
      from: '',
      signature: deliverySignatures,
    },
    description: trackingData?.OnTime360Object?.Description,
    weight: calculateWeight,
  };

  const downloadAttachmentHandler = imgData => {
    downloadAttachmentUtils(imgData);
  };

  const showTrackingInfoHandler = () => {
    if (!trackingNumber) {
      openNotification('warning', 'Warning', enterTrackingNumberMessage);
      return;
    }
    dispatch(getOrderTrackingDetails(trackingNumber));
  };

  return (
    <div className='order-tracking-container'>
      <main className='order-tracking-main'>
        <header className='order-tracking-header'>
          <div className='tracking-num-span'>
            <h3>Tracking Number</h3>
            <input
              type='text'
              className='track-num-input'
              value={trackingNumber}
              onChange={e => setTrackingNumber(e.target.value)}
            />
          </div>
          <button
            className='tracking-info-btn'
            onClick={showTrackingInfoHandler}
          >
            Show Tracking Info
          </button>
        </header>
        {trackingData ? (
          <>
            <section className='tracking-section'>
              <div className='tracking-details'>
                <div className='track-details-head'>
                  <strong>
                    Tracking Details For # {orderTrackingDetailsObj.trackingNum}
                  </strong>
                </div>
                <div className='track-details-main'>
                  <div className='details'>
                    <strong>Service Type</strong>
                    <br />
                    <strong>{orderTrackingDetailsObj.serviceType}</strong>
                  </div>
                  <div className='details'>
                    <strong>Status</strong>
                    <br />
                    <strong>{orderTrackingDetailsObj.orderStatus}</strong>
                  </div>
                  <div className='details'>
                    <strong>{'Delivery Time'}</strong>
                    <br />
                    <strong>{orderTrackingDetailsObj.deliveryTime}</strong>
                  </div>
                </div>
                <div className='track'>
                  <div className='steps-icon-container'>
                    <div className='svg-wrapper'>
                      <img src={inProdTrackingSvg} alt='' width={62} />
                      <img src={cartTrackingSvg} alt='' width={70} />
                      <img src={shipmentTrackingSvg} alt='' width={72} />
                      <img src={deliveryTackingSvg} alt='' width={70} />
                    </div>
                  </div>
                  <div className='steps-wrapper'>
                    <Steps
                      current={trackingRouteStatus(
                        orderTrackingDetailsObj?.orderStatus
                      )}
                      progressDot={customDot}
                      items={items}
                      className='tracking-steps'
                    />
                  </div>
                </div>
              </div>
              <button
                className='more-details-btn'
                onClick={() => setOpenDetails(!openDetails)}
              >
                More Details
                {openDetails ? (
                  <span className='pi pi-minus'></span>
                ) : (
                  <span className='pi pi-plus'></span>
                )}
              </button>
            </section>
            <TrackingDetails
              downloadAttachment={downloadAttachmentHandler}
              attachments={attachments}
              openDetails={openDetails}
              trackingDetails={orderTrackingDetailsObj}
            />
          </>
        ) : (
          <div className='tracking-fallback'>
            <h4>
              No order found on this Tracking number, please contact
              <span> {commonConfig.vnpContactNumber}</span> or
              <span> {commonConfig.vnpEmail}</span>
            </h4>
          </div>
        )}
      </main>
    </div>
  );
};

export default OrderTracking;
