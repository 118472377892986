import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Notfound from '../Screens/errorPages/notFound';
import Auth from '../Screens/auth/authScreen';
import OrderEntryScreen from '../Screens/order/orderEntryScreen';
import AddressList from '../Screens/address/addressList';
import ProtectedRoute from './protectedRoute';
import InvoicesScreen from '../Screens/invoice/invoicesScreen';
import InvoiceViewScreen from '../Screens/invoice/invoiceViewScreen';
import MyOrderScreen from '../Screens/order/myOrderScreen';
import OrderInfoScreen from '../Screens/order/orderInfoScreen';
import OrderReportScreen from '../Screens/order/orderReportScreen';
import OrderTrackingScreen from '../Screens/order/orderTrackingScreen';
import Unsubscribe from '../Containers/Unsubscribe/unsubscribe';
import { internalRoutes } from '../Constant/internalRoutes';

const AppRoute = () => {
  return (
    <>
      <Routes>
        <Route
          path={internalRoutes.HOME}
          element={
            <ProtectedRoute>
              <OrderEntryScreen />
            </ProtectedRoute>
          }
        />
        <Route path={internalRoutes.LOGIN} element={<Auth />} />
        <Route
          path={internalRoutes.ADDRESS_LIST}
          element={
            <ProtectedRoute>
              <AddressList />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.INVOICE_LIST}
          element={
            <ProtectedRoute>
              <InvoicesScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.INVOICE_VIEW}
          element={
            <ProtectedRoute>
              <InvoiceViewScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.ORDER_ENTRY}
          element={
            <ProtectedRoute>
              <OrderEntryScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.ORDER_ENTRY_WITH_ID}
          element={
            <ProtectedRoute>
              <OrderEntryScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.ORDER_LIST}
          element={
            <ProtectedRoute>
              <MyOrderScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.ORDER_INFO_WITH_ID}
          element={
            <ProtectedRoute>
              <OrderInfoScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.ORDER_REPORT}
          element={
            <ProtectedRoute>
              <OrderReportScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path={internalRoutes.ORDER_TRACKING}
          element={<OrderTrackingScreen />}
        />
        <Route
          path={internalRoutes.UNSUBSCRIBE}
          element={
            <ProtectedRoute>
              <Unsubscribe />
            </ProtectedRoute>
          }
        />
        <Route path={internalRoutes.NOT_FOUND} element={<Notfound />} />
      </Routes>
    </>
  );
};

export default AppRoute;
