import React, { useEffect } from 'react';
import './unsubscribe.scss';
import { emailImg } from '../../Resource/images';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input } from 'antd';
import { unsubscribeMailService } from '../../Store/Action/unsubscribe/unsubscribe';
import { encodedBase64 } from '../../Util/commonUtility';

const Unsubscribe = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status } = useSelector(state => state.UnsubscribeSlice);

  useEffect(() => {
    const base64 = new URLSearchParams(window.location.search).get('uid');
    const decodedEmail = base64 && encodedBase64(base64);
    form.setFieldsValue({ email: decodedEmail });
  }, []);

  const closeUnsubscribe = () => {
    navigate('/login');
  };

  const unsubscribeMailServiceHandler = values => {
    dispatch(unsubscribeMailService(values));
  };

  return (
    <div className='unsubscribe-container'>
      <main className='unsubscribe-card'>
        {status ? (
          <div className='status-wrapper'>
            <p className='p-head'>Unsubscribed Successfully.</p>
            <p>You have been removed from our mailing list.</p>
          </div>
        ) : (
          <>
            <div className='email-img-warpper'>
              <img src={emailImg} alt='' className='email-img' />
            </div>
            <div className='unsub-form-wrapper'>
              <span className='pi pi-times' onClick={closeUnsubscribe}></span>
              <h2>We're sorry to see you go!</h2>
              <p>
                User Unsubscribe: Update Your Mailing List and Email Preferences
              </p>
              <Form
                form={form}
                onFinish={unsubscribeMailServiceHandler}
                className='unsub-form'
              >
                <Form.Item
                  name={'email'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter Email',
                    },
                  ]}
                >
                  <Input
                    allowClear
                    className='email-input'
                    type='email'
                    placeholder='Enter your Email'
                  />
                </Form.Item>
                <Button htmlType='submit'>Unsubscribe</Button>
              </Form>
            </div>
          </>
        )}
      </main>
    </div>
  );
};

export default Unsubscribe;
