import { Modal } from 'antd';
import React from 'react'
import './confirmModal.scss'

const ConfirmModal = (props) => {
    const { open, onCancel, onOk, confirmTxt} = props;
  return (
    <Modal
        className='delete-draft-modal'
        open={open}
        title={'Delete Confirmation'}
        onOk={onOk}
        onCancel={onCancel}
      >
        <div className='txt-wrapper'>
          <span className='pi pi-info-circle'></span>
          <span className='warn'>{confirmTxt}</span>
        </div>
        <div className='btns'>
          <button onClick={onCancel}>
            <span className='pi pi-times'></span>
            No
          </button>
          <button onClick={() => onOk()}>
            <span className='pi pi-check'></span>
            Yes
          </button>
        </div>
      </Modal>
  )
}

export default ConfirmModal;