import { createAsyncThunk } from '@reduxjs/toolkit';
import Urls from '../../../Constant/urls';
import API from '../../../Api/api';
import {
  checkHttpResponse,
  openNotification,
} from '../../../Util/commonUtility';
import { setMailService } from '../../Reducers/Unsubscrib/unsubscribeSlice';
import { hideSpinner, showSpinner } from '../../Reducers/Spinner/spinnerSlice';
import { commonError } from '../../../Util/config';

export const unsubscribeMailService = createAsyncThunk(
  'unsubscribeMailService',
  async (email, { dispatch }) => {
    try {
      dispatch(showSpinner());
      const response = await API.post(`${Urls.UNSUBSCRIBE_MAIL_API}/`, email);
      dispatch(hideSpinner());
      if (checkHttpResponse(response)) {
        dispatch(setMailService(true));
      }
    } catch (error) {
      openNotification('error', 'Error', commonError);
    }
  }
);
