import React, { useEffect, useRef, useState } from 'react';
import './addressBook.scss';
import { useDispatch, useSelector } from 'react-redux';
import { editAddressSvg, deleteAddressSvg } from '../../../Resource/images';
import {
  deleteAddress,
  getAddressList,
} from '../../../Store/Action/address/addressAction';
import AddressPopup from '../../../Components/Address/popup/addressPopup';
import { getUser } from '../../../Store/Action/user/userAction';
import AgGridAddress from '../../../Components/Common/AgGrid/addressGrid/agGridAddress';
import {
  findCurrentUserObject,
  getCurrentUserId,
} from '../../../Util/commonUtility';
import ConfirmModal from '../../../Components/Common/Confirm/confirmModal';
import { useNavigate } from 'react-router-dom';
import { internalRoutes } from '../../../Constant/internalRoutes';

const AddressBook = () => {
  const dispatch = useDispatch();

  const [openModel, setOpenModel] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [addressFormController, setAddressFormController] = useState({});
  const [gridApi, setGridApi] = useState('');

  const userDetails = useSelector(state => state.AuthSlice);
  const { userObjId: currentUserObjectId, userData: currentUserDetails} = userDetails;
  const { Customer_ontime_id} = userDetails.userData;
  const { addresses } = useSelector(state => state.AddressSlice);
  const [searchValue, setSearchValue] = useState('');
  const [deleteAddrModal, setDeleteAddrModel] = useState({
    isOpen: false,
    cell: {},
  });

  const inputRef = useRef();
  const navigate = useNavigate();
  const currentUser = findCurrentUserObject(currentUserDetails);

  const searchFilterHandler = e => {
    setSearchValue(e.target.value);
    gridApi?.setQuickFilter(e.target.value);
  };

  useEffect(() => {
    dispatch(getUser());
  }, []);

  useEffect(() => {
    if (currentUser && !currentUser?.HasAccessToAddressBook) {
      navigate(internalRoutes.ORDER_ENTRY);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUserObjectId) {
      dispatch(getAddressList(currentUserObjectId));
    }
  }, [currentUserObjectId]);

  const colDef = [
    'Contact Name',
    'Comments',
    'Address Line 2',
    'Phone',
    'City',
    'Email',
    'Address',
    'Company Name',
    'Postal Code',
    'Edit',
    'Delete',
  ];

  const getId = getCurrentUserId();
  const columnData =
    JSON.parse(localStorage.getItem(`customer-address-grid-${getId}`)) || null;
  const columnArr = columnData?.map(col => col.colId);

  const addressMaksedObj = addresses?.map(item => {
    return {
      _id: item._id,
      currentUserObjectId,
      favDelivery:
        item.favoriteDeliveryLocationFor?.length <= 0
          ? false
          : item.favoriteDeliveryLocationFor,
      favPickup:
        item.favoritePickupLocationFor?.length <= 0
          ? false
          : item.favoritePickupLocationFor,
      defaultLocation:
        item.defaultLocationFor?.length <= 0 ? false : item.defaultLocationFor,
      'Contact Name': item?.contactName || '',
      Comments: item?.comments || '',
      'Address Line 2': item?.addressLine2 || '',
      Phone: item?.phone || '',
      City: item?.city || '',
      Email: item?.email || '',
      Address: item?.addressLine1 || '',
      'Company Name': item?.companyName || '',
      'Postal Code': item?.postalCode || '',
      latitudeLongitude: item?.latitudeLongitude || '',
      Edit: <img src={editAddressSvg} alt='Edit' width={18} key={'edit'} />,
      Delete: (
        <img src={deleteAddressSvg} alt='Delete' width={20} key={'delete'} />
      ),
    };
  });

  const handleAddAddress = () => {
    setEditMode(false);
    setOpenModel(true);
    setInitialValues({});
  };

  const onCancel = () => {
    setDeleteAddrModel({ isOpen: false, cell: {} });
  };

  const handleCellClicked = cell => {
    if (cell.value.key === 'edit') {
      setEditMode(true);
      setOpenModel(true);
      setInitialValues(cell.data);
      setAddressFormController();
      return;
    }
    if (cell.value.key === 'delete') {
      setDeleteAddrModel({ isOpen: true, cell });
    }
  };
  const deleteAddressHandler = () => {
    deleteAddrModal && dispatch(
        deleteAddress({
          id: deleteAddrModal?.cell?.data?._id,
          userObjId: deleteAddrModal?.cell.data?.userObjId,
        })
      );
    setDeleteAddrModel(false)
  };

  return (
    <>
      <ConfirmModal
        open={deleteAddrModal.isOpen}
        onCancel={onCancel}
        onOk={deleteAddressHandler}
        confirmTxt={'Do you want to delete this Address?'}
      />
      <div className='address-book-container'>
        <AddressPopup
          openModel={openModel}
          editMode={editMode}
          setOpenModel={setOpenModel}
          initialValues={initialValues}
          addressFormController={addressFormController}
          Customer_ontime_id={Customer_ontime_id}
          currentUserObjectId={currentUserObjectId}
        />
        <div className='address-book-header'>
          <button className='add-location-btn' onClick={handleAddAddress}>
            Add Location
          </button>
          <input
            type='text'
            ref={inputRef}
            placeholder='Search'
            className='search-location'
            onChange={searchFilterHandler}
          />
        </div>
        <div className='grid-table-wrapper'>
          <AgGridAddress
            inputRef={inputRef}
            data={addressMaksedObj}
            colDef={columnArr ? columnArr : colDef}
            setEditMode={setEditMode}
            setOpenModel={setOpenModel}
            setInitialValues={setInitialValues}
            setAddressFormController={setAddressFormController}
            searchFilterHandler={searchFilterHandler}
            setGridApi={setGridApi}
            handleCellClicked={handleCellClicked}
            gridApi={gridApi}
          />
        </div>
      </div>
    </>
  );
};

export default AddressBook;
