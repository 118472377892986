import { Form, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import './orderEntry.scss';
import AddressDetails from './addressDetails/addressDetails';
import ParcelDetails from './parcelDetails/parcelDetails';
import SelectShipment from './selectShipment/SelectShipment';
import Review from './review/review';
import {
  locationGreySvg,
  parcelBlueSvg,
  parcelGreySvg,
  reviewBlueSvg,
  reviewGreySvg,
  shipMentBlueSvg,
  shipmentGreySvg,
} from '../../../Resource/images';
import {
  createMultiplePackagesArray,
  getCurrentUserId,
  isObjectBlank,
  isObjectBlankForDraft,
  maskPhoneNumber,
  openNotification,
  separatePickupAndDeliveryFormData,
} from '../../../Util/commonUtility';
import {
  editOrderApi,
  getOrderInfo,
  getPackages,
  getSettings,
  placeOrderApi,
  quoteApi,
  saveOrderAsDraft,
} from '../../../Store/Action/order/orderAction';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  setMemorizeFieldsValues,
  setPlaceOrderSteperDetails,
} from '../../../Store/Reducers/Address/AddressSlice';
import {
  setMaskedPackages,
  setQuoteSubmitted,
  setShowQuote,
} from '../../../Store/Reducers/Order/orderSlice';
import dayjs from 'dayjs';
import { orderFilterBtnsENUMS } from '../../../Constant/enums';
import {
  changedFormData,
  refetchPriceMessage,
  selectDeliveryLocation,
  selectPickupLocation,
  selectQuote,
} from '../../../Util/config';

const OrderEntry = () => {
  const [addressDetailsFormInstance] = Form.useForm();
  const [parcelDetailsFormInstance] = Form.useForm();
  const [datePickerForm] = Form.useForm();

  const [isFormEdited, setIsFormEdited] = useState(false);
  const [changedPriceMod, setChangedPriceMod] = useState(false);
  const [selectedService, setSelectedServices] = useState({});
  const {
    packages,
    quoteSubmitted,
    showQuote,
    settings,
    packageDescription,
    quoteData,
  } = useSelector(state => state.OrderSlice);
  const { userObjId } = useSelector(state => state.AuthSlice);
  const {
    latitudeLongitude,
    placeOrderSteperDetails,
    locationId,
    memorizeFieldsValues,
  } = useSelector(state => state.AddressSlice);
  const [current, setCurrent] = useState(0);
  const { tax } = settings;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: OrderidFromParams } = useParams();
  const { orderInfo } = useSelector(state => state.OrderSlice);

  useEffect(() => {
    dispatch(getPackages());
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    OrderidFromParams && dispatch(getOrderInfo(OrderidFromParams));
  }, [OrderidFromParams]);

  const customDot = (dot, { title }) => {
    const setIcons = () => {
      switch (title) {
        case 'Address Details':
          return (
            <div className='ant-steps-icon-dot'>
              <img src={locationGreySvg} />
            </div>
          );
        case 'Parcel Details':
          return (
            <div className='ant-steps-icon-dot'>
              {current >= 1 ? (
                <img src={parcelBlueSvg} style={{ margin: '4px 1px 0 0' }} />
              ) : (
                <img src={parcelGreySvg} style={{ margin: '4px 1px 0 0' }} />
              )}
            </div>
          );
        case 'Select Shipment':
          return (
            <div className='ant-steps-icon-dot'>
              {current >= 2 ? (
                <img src={shipMentBlueSvg} style={{ marginTop: '7px' }} />
              ) : (
                <img src={shipmentGreySvg} style={{ marginTop: '7px' }} />
              )}
            </div>
          );
        case 'Review Details':
          return (
            <div className='ant-steps-icon-dot'>
              {current >= 3 ? (
                <img src={reviewBlueSvg} />
              ) : (
                <img src={reviewGreySvg} />
              )}
            </div>
          );
        default:
          return '';
      }
    };
    return setIcons();
  };
  const packagesArray = createMultiplePackagesArray(
    parcelDetailsFormInstance.getFieldValue()
  );

  const steps = [
    {
      title: 'Address Details',
      content: (
        <AddressDetails
          form={addressDetailsFormInstance}
          setIsFormEdited={setIsFormEdited}
        />
      ),
    },
    {
      title: 'Parcel Details',
      content: (
        <ParcelDetails
          packages={packages}
          form={parcelDetailsFormInstance}
          datePickerForm={datePickerForm}
        />
      ),
    },
    {
      title: 'Select Shipment',
      content: (
        <SelectShipment
          changedPriceMod={changedPriceMod}
          setChangedPriceMod={setChangedPriceMod}
          selectedService={selectedService}
          setSelectedServices={setSelectedServices}
        />
      ),
    },
    {
      title: 'Review Details',
      content: (
        <Review
          selectedService={selectedService}
          tax={tax}
          details={placeOrderSteperDetails}
          packagesArray={packagesArray}
        />
      ),
    },
  ];

  const validateErrorFields = errorArr => {
    const separateValue = separatePickupAndDeliveryFormData(
      addressDetailsFormInstance.getFieldsValue()
    );
    return isObjectBlank(separateValue?.pickup)
      ? openNotification('error', 'Error', selectPickupLocation)
      : isObjectBlank(separateValue?.delivery)
      ? openNotification('error', 'Error', selectDeliveryLocation)
      : '';
  };

  const maskDataForQuoteApi = () => {
    const addressDetailsData = addressDetailsFormInstance.getFieldValue();
    const currentUserId = getCurrentUserId();
    return {
      CollectionLocation: {
        CompanyName: addressDetailsData?.CompanyName,
        ContactName: addressDetailsData?.ContactName,
        AddressLine1: addressDetailsData?.AddressLine1,
        AddressLine2: addressDetailsData?.AddressLine2,
        City: addressDetailsData?.City,
        Comments: addressDetailsData?.Comments,
        Email: addressDetailsData?.Email,
        PostalCode: addressDetailsData?.PostalCode,
        LatitudeLongitude: latitudeLongitude.latitudeLongitudeForPickup || '',
        Phone: maskPhoneNumber(addressDetailsData?.Phone),
        PhoneExt: addressDetailsData?.PhoneExt || '',
        // ID: locationId.pickupId,
      },
      DeliveryLocation: {
        CompanyName: addressDetailsData?.CompanyNameForDelivery,
        ContactName: addressDetailsData?.ContactNameForDelivery,
        AddressLine1: addressDetailsData?.AddressLine1ForDelivery,
        AddressLine2: addressDetailsData?.AddressLine2ForDelivery,
        City: addressDetailsData?.CityForDelivery,
        Comments: addressDetailsData?.CommentsForDelivery,
        Email: addressDetailsData?.EmailForDelivery,
        PostalCode: addressDetailsData?.PostalCodeForDelivery,
        LatitudeLongitude: latitudeLongitude.latitudeLongitudeForDelivery || '',
        Phone: maskPhoneNumber(addressDetailsData?.PhoneForDelivery),
        PhoneExt: addressDetailsData?.PhoneExtForDelivery || '',
        // ID: locationId.deliveryId, //create diffrent  object for draft and quote api
      },
      CustomerId: userObjId,
      CustomerOnTimeId: currentUserId,
    };
  };
  const next = async () => {
    //create diffrent functions
    try {
      const formValues = addressDetailsFormInstance.getFieldsValue();
      const separateValue = separatePickupAndDeliveryFormData(
        formValues
      );
      if (current === 0) {
        if (isFormEdited) {
          openNotification('error', 'Error', changedFormData);
          return;
        }

        if (Object.keys(formValues)?.length <= 0) {
          return openNotification('error', 'Error', 'please select pickup location')
        }
        else if (!(separateValue?.pickup)) {
          return openNotification('error', 'Error', selectPickupLocation)
        }else if(!(separateValue?.delivery)){
          return openNotification('error', 'Error', selectDeliveryLocation)
        }
        const validate = await addressDetailsFormInstance.validateFields();
        const addressDetailsData = addressDetailsFormInstance.getFieldValue();

        dispatch(
          setMemorizeFieldsValues({
            ...memorizeFieldsValues,
            ...addressDetailsData,
            ...locationId,
          })
        );

        dispatch(setPlaceOrderSteperDetails(maskDataForQuoteApi()));
      }

      if (current === 1) {
        const localpackagesArray = createMultiplePackagesArray(
          parcelDetailsFormInstance.getFieldValue()
        );
        await parcelDetailsFormInstance.validateFields();
        const PickupDateTime = dayjs(
          datePickerForm.getFieldValue(['date-time'])
        ).toISOString();
        if (localpackagesArray.length <= 0) return;
        setCurrent(current + 1);
        const quoteObj = {
          ...placeOrderSteperDetails,
          Packages: localpackagesArray,
          PickupDateTime,
        };
        dispatch(
          setMemorizeFieldsValues({
            ...memorizeFieldsValues,
            packages: localpackagesArray,
          })
        );
        dispatch(quoteApi(quoteObj));
        dispatch(setMaskedPackages(packagesArray));
      }

      if (current === 2) {
        if (changedPriceMod) {
          openNotification('error', 'Error', refetchPriceMessage);
          return;
        }
        if (Object.keys(selectedService).length <= 0) {
          openNotification('error', 'Error', selectQuote);
          return;
        }
      }
      setCurrent(current + 1);
    } catch (error) {
      if (current === 1) {
        return;
      }
      validateErrorFields(error.errorFields);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
    if (current === 1) {
      const localpackagesArray = createMultiplePackagesArray(
        parcelDetailsFormInstance.getFieldValue()
      );
      setIsFormEdited(false);
      dispatch(
        setMemorizeFieldsValues({
          ...memorizeFieldsValues,
          packages: localpackagesArray,
        })
      );
    }
    if (current === 2) {
      setChangedPriceMod(false)
      setSelectedServices({});
      dispatch(
        setMemorizeFieldsValues({
          ...memorizeFieldsValues,
          selectedService: { event: '', className: '' },
        })
      );
    }
  };

  const saveAsDraftSameObj = () => {
    const maskedObjForOrderDraft = {
      ...placeOrderSteperDetails,
      Packages: packagesArray,
    };
    dispatch(
      saveOrderAsDraft({
        formData: maskedObjForOrderDraft,
        bool: false,
        userObjId,
      })
    );
    navigate('/order/list');
  };

  const goBack = () => {
    setCurrent(0);
    dispatch(setShowQuote(false));
    dispatch(setQuoteSubmitted({ IsQuote: false }));
  };
  const draftOrder = async () => {
    if (current === 0) {
      //address details page
      try {
        if (isFormEdited) {
          openNotification('error', 'Error', changedFormData);
          return;
        }
        const validate = await addressDetailsFormInstance.validateFields();
        const addressDetailsData = addressDetailsFormInstance.getFieldValue();
        dispatch(setPlaceOrderSteperDetails(maskDataForQuoteApi()));

        dispatch(
          setMemorizeFieldsValues({
            ...memorizeFieldsValues,
            ...addressDetailsData,
            ...locationId,
          })
        );

        if (isObjectBlankForDraft(parcelDetailsFormInstance.getFieldValue())) {
          setCurrent(current + 1);
        } else {
          const maskedObjForOrderDraft = {
            ...placeOrderSteperDetails,
            Packages: packagesArray,
          };
          dispatch(
            saveOrderAsDraft({
              formData: maskedObjForOrderDraft,
              bool: false,
              userObjId,
            })
          );
        }
      } catch (error) {
        validateErrorFields(error.errorFields);
      }
    }
    if (current === 1) {
      // parcel details page
      try {
        await parcelDetailsFormInstance.validateFields();
        const maskedObjForOrderDraft = {
          ...placeOrderSteperDetails,
          Packages: packagesArray,
        };
        dispatch(
          saveOrderAsDraft({
            formData: maskedObjForOrderDraft,
            bool: false,
            userObjId,
          })
        );
        navigate('/order/list');
      } catch (error) {
        if (error) {
          return;
        }
      }
    }

    if (current === 2) {
      saveAsDraftSameObj();
    }
    if (current === 3) {
      saveAsDraftSameObj();
    }
  };

  const placeOrder = () => {
    // hear i put both id, locationId is redux state which will persist location id and we can use that id over application,
    // and we are not getting location id in order grid cell, i am taking id from OrderInfo (order api data)
    const pickupWithId = {
      ...placeOrderSteperDetails.CollectionLocation,
      ID: locationId?.pickupId || orderInfo?.CollectionLocation?._id,
      Zone: {},
    };
    const deliveryWithId = {
      ...placeOrderSteperDetails.DeliveryLocation,
      ID: locationId?.deliveryId || orderInfo?.DeliveryLocation?._id,
      Zone: {},
    };

    let maskedObj = {
      CollectionLocation: { ...pickupWithId },
      DeliveryLocation: { ...deliveryWithId },
      CustomerId: placeOrderSteperDetails.CustomerId,
      CustomerOnTimeId: placeOrderSteperDetails.CustomerOnTimeId,
      Quote: { ...selectedService.service },
      Packages: packagesArray,
      TaxArray: tax.Taxes,
      Description: packageDescription,
      SelectedProviderType: selectedService?.parentProviderName || 'DLVRD',
      isFromOutside: selectedService?.isFromOutside || false,
      SelectedProviderId: selectedService?.parentProviderId
    };

    if (memorizeFieldsValues?.type === orderFilterBtnsENUMS.draft) {
      maskedObj = {
        OrderId: OrderidFromParams,
        OnTime360Object: orderInfo?.OnTime360Object,
        ...maskedObj,
      };
      dispatch(editOrderApi({ data: maskedObj, navigate }));
      return;
    }
    dispatch(placeOrderApi({ data: maskedObj, navigate }));
  };

  const editOrder = () => {
    const pickupWithId = {
      ...placeOrderSteperDetails.CollectionLocation,
      ID: orderInfo?.CollectionLocation?._id,
      Zone: {},
    };
    const deliveryWithId = {
      ...placeOrderSteperDetails.DeliveryLocation,
      ID: orderInfo?.DeliveryLocation?._id,
      Zone: {},
    };

    const editOrderPayload = {
      CollectionLocation: { ...pickupWithId },
      DeliveryLocation: { ...deliveryWithId },
      CustomerId: placeOrderSteperDetails.CustomerId,
      CustomerOnTimeId: placeOrderSteperDetails.CustomerOnTimeId,
      OrderId: memorizeFieldsValues?.orderId,
      Quote: { ...selectedService.service },
      Packages: packagesArray,
      TaxArray: tax.Taxes,
      Description: packageDescription,
      OnTime360Object: orderInfo?.OnTime360Object,
      SelectedProviderType: selectedService?.parentProviderName || 'DLVRD',
      isFromOutside: selectedService?.isFromOutside || false,
      SelectedProviderId: selectedService?.parentProviderId
    };
    dispatch(editOrderApi({ data: editOrderPayload, navigate }));
  };

  return (
    <div className='order-entry-container'>
      <main className='order-steper-wrapper'>
        <div className='order-steper-header'>
          <Steps
            current={current}
            labelPlacement='vertical'
            progressDot={customDot}
            items={steps}
            className='order-steps'
            responsive={false}
          />
        </div>
        <div className='steper-content-container'>
          <header className='steper-heading'>
            <span>{current + 1}</span>
            {steps[current].title === 'Select Shipment'
              ? 'Select Shipment Partner'
              : steps[current].title}
          </header>
          <section className='steper-content-wrapper'>
            {steps[current].content}
          </section>
        </div>
        <footer className='steper-btn-container'>
          {current > 0 &&
            (quoteSubmitted?.IsQuote ? (
              <button
                className='steper-back-btn'
                style={{
                  margin: '0 8px',
                }}
                onClick={() => goBack()}
              >
                Go Back
              </button>
            ) : (
              <button
                className='steper-back-btn'
                // style={{
                //   margin: '0 8px',
                // }}
                onClick={() => prev()}
              >
                Back
              </button>
            ))}
          {memorizeFieldsValues?.type !== orderFilterBtnsENUMS.submitted && (
            <button
              className={`save-draft-btn ${showQuote && 'hide'} ${
                current === 2 && quoteData.length <= 0 && !showQuote && 'hide'
              }`}
              onClick={draftOrder}
            >
              Save as a Draft
            </button>
          )}
          {current < steps.length - 1 && (
            <button
              type='submit'
              onClick={() => next()}
              className={`steper-next-btn ${showQuote && 'hide'} ${
                current === 2 && quoteData.length <= 0 && !showQuote && 'hide'
              }`}
            >
              Next
            </button>
          )}
          {current === steps.length - 1 && (
            <button
              type='primary'
              className={`steper-submit-btn ${
                quoteSubmitted?.IsQuote && 'hide'
              }`}
              onClick={
                memorizeFieldsValues?.type === orderFilterBtnsENUMS.submitted
                  ? editOrder
                  : placeOrder
              }
            >
              {memorizeFieldsValues?.type === orderFilterBtnsENUMS.submitted
                ? 'Edit Order'
                : 'Place Order'}
            </button>
          )}
        </footer>
      </main>
    </div>
  );
};

export default OrderEntry;
