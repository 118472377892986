import React, { useEffect, useState } from 'react';
import './order-columns-menu.scss';
import 'primeicons/primeicons.css';
import { useDispatch } from 'react-redux';
import { setGridColumsVisibility } from '../../../Store/Action/order/orderAction';
import {
  filterColumnOnSelect,
  findCurrentUserObject,
  getCurrentUserId,
  maskGridForShowHideColumnMenuList,
} from '../../../Util/commonUtility';
import { orderFilterBtnsENUMS } from '../../../Constant/enums';

const OrderColumnsMenu = ({
  setSelectedColumn,
  isMenuOpen,
  setIsMenuOpen,
  colObj,
  gridApi,
  userData,
  currentFilterType,
}) => {
  const customerId = getCurrentUserId();
  const [currentUserColumnMenuList, setCurrentUserColumnMenuList] = useState();

  useEffect(() => {
    const jsonUserGrid =
      findCurrentUserObject(userData)?.ColumnStateSaveOrderListShowHide;
    const finalGrid = maskGridForShowHideColumnMenuList(jsonUserGrid, colObj); // refector logic as MyOrder grid
    setCurrentUserColumnMenuList(finalGrid);
  }, [userData]);

  const [searchArr, setSearchArr] = useState([]); // refector logic
  const [searchTxt, setSearchTxt] = useState();
  const dispatch = useDispatch();

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  const checkBoxHandler = (e, colData) => {
    const newArrForGrid = gridApi.columnApi.getColumnState()?.map((col, i) => {
      if (col?.colId === colObj[colData?.colId]) {
        return { colId: getKeyByValue(colObj, col?.colId), hide: !col.hide };
      } else {
        return { colId: getKeyByValue(colObj, col?.colId), hide: col.hide };
      }
    });

    const newArr = currentUserColumnMenuList?.map((col, i) => {
      if (col?.colId === colData?.colId) {
        return { ...col, hide: !col.hide };
      } else {
        return col;
      }
    });
  
    gridApi.columnApi.applyColumnState({
      state: newArrForGrid,
      applyOrder: true,
    });

    setCurrentUserColumnMenuList(newArr);
    localStorage.setItem(
      `${customerId}_ColumnStateSaveOrderList`,
      JSON.stringify(newArrForGrid)
    );
    const maskShowHideMenuList = newArr
      .filter(col => !col.hide)
      .map(col => col.colId);

    dispatch(
      setGridColumsVisibility({ grid: JSON.stringify(maskShowHideMenuList) })
    );
    const maskedArr = newArrForGrid.map(col => {
      return { field: colObj[col?.colId], hide: col?.hide };
    });

    setSelectedColumn(filterColumnOnSelect(maskedArr, currentFilterType));

    const updatedSearchArray = searchArr?.map(item =>
      item?.colId === colData?.colId ? { ...item, hide: !item?.hide } : item
    );
    setSearchArr(updatedSearchArray);
  };

  const searchHandler = e => {
    setSearchTxt(e.target.value);
  };

  useEffect(() => {
    if (searchTxt) {
      const cloneArr = [...currentUserColumnMenuList];
      const filterArr = cloneArr?.filter(col =>
        colObj[col.colId]?.toLowerCase()?.includes(searchTxt?.toLowerCase())
      );
      setSearchArr(filterArr);
    } else {
      setSearchArr(currentUserColumnMenuList); // refector this
    }
  }, [searchTxt]);

  return (
    <div className={`column-menu-container ${!isMenuOpen && 'hide'}`}>
      <div className='menu-left'>
        <div className='search-input-wrapper'>
          <input type='text' onChange={searchHandler} />
          <span className='pi pi-search custom-class'></span>
        </div>
        <div className='column-list'>
          {(searchArr || currentUserColumnMenuList)?.map((col, i) => (
            <div
              className={'list'}
              style={{
                backgroundColor: !col.hide ? '#b3d0dd33' : 'transparent',
              }}
              key={i}
            >
              <input
                type='checkbox'
                name=''
                disabled={
                  colObj[col?.colId] === 'Edit' || (currentFilterType === orderFilterBtnsENUMS.draft && (colObj[col?.colId] === 'Tracking Number')) ||
                  (colObj[col?.colId] === 'Delete' && true)
                }
                id={i}
                onChange={e => checkBoxHandler(e, col)}
                checked={!col?.hide}
              />
              <label
                style={{
                  color:
                    colObj[col?.colId] === 'Edit' ||
                    colObj[col?.colId] === 'Delete' || 
                    (currentFilterType === orderFilterBtnsENUMS.draft && (colObj[col?.colId] === 'Tracking Number'))
                      ? 'grey'
                      : 'black',
                }}
                htmlFor={i}
              >
                {colObj[col?.colId]}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className='menu-right'>
        <button className='cols-btn' onClick={() => setIsMenuOpen(!isMenuOpen)}>
          Columns
        </button>
      </div>
    </div>
  );
};

export default OrderColumnsMenu;
