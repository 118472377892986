import React, { useEffect, useRef } from 'react';
import './order-pdf-report.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  downloadReport,
  getOrderReport,
} from '../../../Store/Action/order/orderAction';
import ReportPDF from '../../../Components/Order/report/reportPDF';
import { useReactToPrint } from 'react-to-print';
import { getUser } from '../../../Store/Action/user/userAction';

const OrderPDFReport = () => {
  const dipsatch = useDispatch();
  const { report: pdfData } = useSelector(state => state.OrderSlice);

  const setReportLabel = path => {
    const label = path?.split('/')?.slice(-2)[0];

    switch (label) {
      case 'SL':
        return 'Shipping Label';
      case 'WB':
        return 'Way Bill';
      case 'BOL':
        return 'Bill Of Landing';
      default:
        return '';
    }
  };
  const senitizedUrl = window.location.pathname.split('/').slice(-2).join('/');

  let pdfExportComponent = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => pdfExportComponent.current,
  });

  useEffect(() => {
    dipsatch(getUser());
    dipsatch(getOrderReport({ url: senitizedUrl }));
  }, []);

  const downloadPDFHandler = () => {
    dipsatch(downloadReport(pdfData));
  };
  return (
    <div className='report-pdf-container'>
      <div className='report-wrapper'>
        <h2>View Report</h2>
        <div className='report-header'>
          <h3>{setReportLabel(window.location.pathname)}</h3>
          <div className='report-button'>
            <a
              className='download-pdf-btn'
              onClick={() => {
                handlePrint();
              }}
            >
              <span className='pi pi-arrow-down'></span>
              Print
            </a>
            <a className='download-pdf-btn' onClick={downloadPDFHandler}>
              <span className='pi pi-arrow-down'></span>
              Download
            </a>
          </div>
        </div>
        <div ref={pdfExportComponent} className='pdf-wrapper'>
          {pdfData && <ReportPDF pdfData={pdfData} />}
        </div>
      </div>
    </div>
  );
};

export default OrderPDFReport;
