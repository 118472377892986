import React from 'react';
import MainLayout from '../../Components/Layout/mainLayout';
import OrderPDFReport from '../../Containers/Order/report/OrderPDFReport';

const OrderReportScreen = () => {
  return (
    <MainLayout>
      <OrderPDFReport />
    </MainLayout>
  );
};

export default OrderReportScreen;
