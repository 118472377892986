import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'Auth',
  initialState: {
    userData: [],
    isUserLogin: false,
    userObjId: null,
  },
  reducers: {
    getUserData(state, { payload }) {
      state.userObjId = payload._id;
      state.userData = payload;
    },
    loginStatus(state, { payload }){
      state.isUserLogin = payload;
    }
  },
});

export const { getUserData, loginStatus } = authSlice.actions;
export default authSlice.reducer;