import React from 'react';
import './sliderMenu.scss';
import Navlinks from '../navlinks/navlinks';


const SliderMenu = ({ isSlider, setIsSlider, onClose}) => {

  return (
    <aside className={`slider-container ${isSlider && 'active'}`}>
      <div className='slider-links-wrapper'>
        <Navlinks setIsSlider={setIsSlider} onClose={onClose} />
      </div>
    </aside>
    // <Drawer
    //   title='Menu'
    //   placement={'left'}
    //   closable={false}
    //   onClose={() => setIsSlider(false)}
    //   open={isSlider}
    //   key={'left'}
    //   extra={<Space><span className='pi pi-times' onClick={onClose}></span></Space>}
    // >
    //   <Navlinks setIsSlider={setIsSlider} onClose={onClose} />
    // </Drawer>
  );
};

export default SliderMenu;
