import React from 'react';
import './tracking-details.scss';
import PickupDeliveryTemplate from './pickupDeliveryTemplate';
import { ellipse1, ellipse2 } from '../../../Resource/images';
import { commonConfig } from '../../../Config/config';

const TrackingDetails = props => {
  const { openDetails, trackingDetails, attachments, downloadAttachment } = props;
  return (
    <div className={`tracking-details-container ${!openDetails && 'hide'}`}>
      <img src={ellipse1} alt='' className='ellips1' />
      <img src={ellipse2} alt='' className='ellips2' />
      <div className='top-wrapper'>
        <div className='details-container'>
          <PickupDeliveryTemplate
          key={'pickupTemplate'}
            flag={true}
            trackingDetails={trackingDetails}
          />
        </div>
        <div className='details-container'>
          <PickupDeliveryTemplate
          key={'deliveryTemplate'}
            flag={false}
            trackingDetails={trackingDetails}
          />
        </div>
      </div>
      <hr />
      <div className='middle-wrapper'>
        <div className='details'>
          <p>Description: </p>
          <span>{trackingDetails?.description}</span>
        </div>
        <div className='details'>
          <p>Weight: </p>
          <span>{trackingDetails?.Weight || '0'} {commonConfig.weightUnit}</span>
        </div>
      </div>
      <hr />
      <div className='footer-wrapper'>
        <div className='attachment-wrapper'>
          <h3>Attachment</h3>
          {attachments?.length > 0 ? (
            <div className='img-wrapper'>
              {attachments?.map(attch => (
                <div className='img'>
                  <img
                    src={`data:image/jpg;base64,${attch?.Contents}`}
                    alt=''
                    className='attachment-style'
                  />
                  <span onClick={() => downloadAttachment(attch)}>Download</span>
                </div>
              ))}
            </div>
          ) : (
            <div className='not-found'>N/A</div>
          )}
        </div>

      </div>
    </div>
  );
};

export default TrackingDetails;
