import React from 'react'
import { notification } from 'antd';

const Notification = () => {
    const [api, contextHolder] = notification.useNotification();
  return (
    <div className='custom-notification'> { contextHolder } </div>
  )
}

export default Notification;