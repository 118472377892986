export const commonConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  ngsUrl: process.env.REACT_APP_NGS_URL,
  CompanyName: 'VNP',
  companyNameSmall: 'Vnp',
  VNPaddress: 'Vnp Transport 3590 Rue Griffith. Saint-Laurent,Quebec. H4T 1A7',
  country: 'Canada',
  vnpContactNumber: '514-312-1101',
  vnpEmail: 'nemish@vnptransport.ca',
  vnpWebsite: 'www.vnptransport.com',
  copyrightText: `© ${new Date().getFullYear()} VNP Transport. All rights reserved.`,
  weightUnit: 'lb',
  HWLUnit: 'in'
};
