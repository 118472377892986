import React, { useEffect, useState } from 'react';
import './order-info.scss';
import { ellipse1, ellipse2 } from '../../../Resource/images';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOrderAttachments,
  getOrderInfo,
  getOrderSignatures,
  refreshOrderFromOnTime,
} from '../../../Store/Action/order/orderAction';
import { useParams } from 'react-router-dom';
import OrderInfoLeft from '../../../Components/Order/info/left/orderInfoLeft';
import OrderInfoRight from '../../../Components/Order/info/right/orderInfoRight';
import TrackingLinkModel from '../../../Components/Order/trackingLinkModal/trackingLinkModal';
import { getUser } from '../../../Store/Action/user/userAction';
import { downloadAttachmentUtils, formatDateIn_12Hour } from '../../../Util/commonUtility';
import { setOrderInfo } from '../../../Store/Reducers/Order/orderSlice';

const OrderInfo = () => {
  const dispatch = useDispatch();
  const { orderInfo, deliverySignatures, collectionSignatures, attachments } =
    useSelector(state => state.OrderSlice);
  const { userData } = useSelector(state => state.AuthSlice);
  const { id: orderId } = useParams();
  const [openModel, setOpenModel] = useState(false);
  const handlePrint = () => {
    window.print();
  };

  const reportBtnHandler = data => {
    if (data.name === 'Print') {
      handlePrint();
    }
    if (data.route) {
      window.open(`/order/report/${data.route}/${orderId}`, '_blank');
    }
    if (data.name === 'Send Tracking Link') {
      setOpenModel(true);
    }
    if (data.name === 'Refresh Order') {
      const onTimeId = orderInfo?.OnTime360Object?.ID;
      if (window.confirm('Are you sure you want to refresh the order ?')) {
        dispatch(refreshOrderFromOnTime(orderId));
        dispatch(getOrderSignatures({ id: onTimeId, type: 0 }));
        dispatch(getOrderSignatures({ id: onTimeId, type: 1 }));
        dispatch(getOrderAttachments(onTimeId));
      }
    }
  };

  useEffect(() => {
    dispatch(getUser());
    dispatch(getOrderInfo(orderId));
    return () => {
      dispatch(setOrderInfo({})); // reset orderInfo data
    };
  }, []);

  useEffect(() => {
    const onTimeId = orderInfo?.OnTime360Object?.ID;
    if (onTimeId) {
      dispatch(getOrderSignatures({ id: onTimeId, type: 0 }));
      dispatch(getOrderSignatures({ id: onTimeId, type: 1 }));
      dispatch(getOrderAttachments(onTimeId));
    }
  }, [orderInfo?.OnTime360Object?.ID]);
  const printBtns = [
    {
      name: 'Print Shipping Label',
      route: `SL`,
    },
    {
      name: 'Print Way Bill',
      route: `WB`,
    },
    {
      name: 'Print Bill Of Landing',
      route: `BOL`,
    },
    {
      name: 'Print',
      route: ``,
    },
    {
      name: 'Send Tracking Link',
    },
    {
      name: 'Refresh Order',
    },
  ];

  const orderInfoLeft = {
    trackingNumber: orderInfo?.OnTime360Object?.TrackingNumber,
    dateSubmited: formatDateIn_12Hour(new Date(orderInfo?.CreatedDate)),
    serviceLevel: orderInfo?.Quote?.serviceName,
    currentStatus: orderInfo?.OrderStatus,
    dateTime:
      orderInfo?.OrderStatusArray && formatDateIn_12Hour(new Date(orderInfo?.OrderStatusArray[0]?.DateTime)),
    orderStatus:
      orderInfo?.OrderStatusArray && orderInfo?.OrderStatusArray[0]?.status,
  };

  const orderInfoRight = {
    requestedBy: 'Test Data',
    pickCompanyName: orderInfo?.CollectionLocation?.CompanyName,
    pickContactName: orderInfo?.CollectionLocation?.ContactName,
    pickAddr1: orderInfo?.CollectionLocation?.AddressLine1,
    pickAddr2: orderInfo?.CollectionLocation?.AddressLine2,
    pickCity: orderInfo?.CollectionLocation?.City,
    pickPostalCode: orderInfo?.CollectionLocation?.PostalCode,
    totalCost: orderInfo?.TaxInformation?.OrderAmountWithTax,
    desc: orderInfo?.OnTime360Object?.Description,
    weight: orderInfo?.OnTime360Object?.Weight,
    delivCompanyName: orderInfo?.DeliveryLocation?.CompanyName,
    delivContactName: orderInfo?.DeliveryLocation?.ContactName,
    delivAddr1: orderInfo?.DeliveryLocation?.AddressLine1,
    delivAddr2: orderInfo?.DeliveryLocation?.AddressLine2,
    delivCity: orderInfo?.DeliveryLocation?.City,
    delivPostalCode: orderInfo?.DeliveryLocation?.PostalCode,
    distance: orderInfo?.Quote?.distance,
  };

  const orderInfoObj = {
    printBtns: printBtns,
    headerTxt:
      'For viewing & printing of label, waybill & landing requires Adobe Reader installed on your system',
  };

  const downloadAttachmentHandler = imgData => {
    downloadAttachmentUtils(imgData);
  };

  return (
    <div className='order-info-container'>
      <TrackingLinkModel
        openModel={openModel}
        setOpenModel={setOpenModel}
        orderData={orderInfo}
        customerInfo={userData}
      />
      <div className='order-info-main'>
        <div className='order-info-header'>
          <div className='header-btns-wrapper'>
            {orderInfoObj.printBtns.map((btn, i) => (
              <button
                className='header-btns'
                key={i}
                onClick={() => reportBtnHandler(btn)}
              >
                {btn.name}
              </button>
            ))}
          </div>
          <span>{orderInfoObj.headerTxt}</span>
        </div>
        <div>
          <div className='info-main'>
            <img src={ellipse1} alt='' className='ellips-1' />
            <img src={ellipse2} alt='' className='ellips-2' />
            <OrderInfoLeft
              orderInfo={orderInfo}
              orderInfoObj={orderInfoLeft}
              deliverySignatures={deliverySignatures}
              collectionSignatures={collectionSignatures}
              attachments={attachments}
              downloadAttachment={downloadAttachmentHandler}
            />
            <OrderInfoRight orderInfoObj={orderInfoRight} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
