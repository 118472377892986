import { useSelector } from 'react-redux';
import './order-info-left.scss';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { formatDateIn_12Hour } from '../../../../Util/commonUtility';

const OrderInfoLeft = props => {
  const {
    orderInfo,
    orderInfoObj,
    deliverySignatures,
    collectionSignatures,
    attachments,
    downloadAttachment,
  } = props;

  const { attachmentsLoader } = useSelector(state => state.spinnerSlice);
  return (
    <div className='info-left'>
      <div className='info-left-top'>
        <div className='top-wrapper'>
          <h3>Tracking Number</h3>
          <h4>{orderInfoObj?.trackingNumber}</h4>
        </div>
        <div className='top-wrapper'>
          <h3>Date Submitted</h3>
          <h4>{orderInfoObj?.dateSubmited}</h4>
        </div>
        <div className='top-wrapper'>
          <h3>Service Level</h3>
          <h4>{orderInfoObj?.serviceLevel}</h4>
        </div>
      </div>
      <div className='status-info'>
        <div className='status'>
          <h3>Current Status</h3>
          <h4>{orderInfoObj?.currentStatus}</h4>
        </div>
        <div className='history'>
          <h3>History</h3>
          <table>
            <thead>
              <tr>
                <th>Date & Time</th>
                <th>|</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {orderInfo?.OrderStatusArray?.map((data, i) => (
                <tr key={i}>
                  <td>
                    {formatDateIn_12Hour(new Date(data.DateTime))}
                  </td>
                  <td className='null-td'></td>
                  <td>{data.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className='signature-info'>
        <div className='signature-wrapper'>
          <h3>Collection Signature</h3>
          {collectionSignatures ? (
            <img
              src={`data:image/jpg;base64,${collectionSignatures}`}
              alt=''
              className='signature-style'
            />
          ) : (
            <div className='not-found'>N/A</div>
          )}
        </div>
        <div className='signature-wrapper'>
          <h3>Delivery Signature</h3>
          {deliverySignatures ? (
            <img
              src={`data:image/jpg;base64,${deliverySignatures}`}
              alt=''
              className='signature-style'
            />
          ) : (
            <div className='not-found'>N/A</div>
          )}
        </div>
        <div
          className={`signature-wrapper ${
            attachments?.length > 0 && 'attachments'
          }`}
        >
          <h3>Attachment</h3>

          {attachmentsLoader ? (
            <div className='loader-wrapper'>
              <Spin
                tip={<span className='loading-txt'>Loading</span>}
                size='small'
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 26,
                      color: 'white',
                    }}
                    spin
                  />
                }
              >
                <></>
              </Spin>
            </div>
          ) : attachments?.length > 0 ? (
            <div className='img-wrapper'>
              {attachments.map(attch => (
                <div className='img'>
                  <img
                    src={`data:image/jpg;base64,${attch.Contents}`}
                    alt=''
                    className='attachment-style'
                  />
                  <span onClick={() => downloadAttachment(attch)}>
                    Download
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className='not-found'>N/A</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderInfoLeft;
