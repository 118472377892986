import { createSlice } from '@reduxjs/toolkit';

const spinnerSlice = createSlice({
  name: 'spinner',
  initialState: {
    spinner: false,
    attachmentsLoader: false,
  },
  reducers: {
    showSpinner(state, action) {
      state.spinner = true;
    },
    hideSpinner(state, action) {
      state.spinner = false;
    },
    showAttchmentsLoader(state, action) {
      state.attachmentsLoader = true;
    },
    hideAttchmentsLoader(state, action) {
      state.attachmentsLoader = false;
    },
  },
});

export const {
  showSpinner,
  hideSpinner,
  showAttchmentsLoader,
  hideAttchmentsLoader,
} = spinnerSlice.actions;
export default spinnerSlice.reducer;
