import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './report-pdf.scss';

const ReportPDF = ({ pdfData }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  return (
    <>
    {pdfData &&
    <Document file={pdfData} className='pdf-report-container'>
      <Page scale={120/72} pageNumber={1}/>
    </Document>}
    </>
  );
};

export default ReportPDF;
