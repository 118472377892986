import React, { useEffect, useState } from 'react';
import { Form, Modal, Input, Switch } from 'antd';
import './addressPopup.scss';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@react-google-maps/api';
import {
  addAddress,
  editAddress,
} from '../../../Store/Action/address/addressAction';
import { InputMask } from 'primereact/inputmask';
import {
  googlePlaceDataMasking,
  manipulateString,
} from '../../../Util/commonUtility';

const AddressPopup = ({
  openModel,
  editMode,
  setOpenModel,
  initialValues,
  Customer_ontime_id,
  currentUserObjectId,
}) => {
  const [form] = Form.useForm();
  const [searchResult, setSearchResult] = useState('');
  const [googlePlaceApiData, setGooglePlaceApiData] = useState(null);

  useEffect(() => {
    form.setFieldsValue({
      addressLine1: googlePlaceApiData?.addressLine1,
      city: googlePlaceApiData?.city || initialValues?.['City'],
      postalCode: googlePlaceApiData?.postalCode
        ? googlePlaceApiData?.postalCode
        : googlePlaceApiData?.postalCode === ''
        ? ''
        : initialValues?.['Postal Code'],
    });
  }, [googlePlaceApiData]);

  const onLoad = autocomplete => {
    setSearchResult(autocomplete);
  };

  const dispatch = useDispatch();

  const onPlaceChanged = () => {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setGooglePlaceApiData(googlePlaceDataMasking(place));
    } else {
      alert('Please enter text');
    }
  };

  const formSubmitHandler = value => {
    const getCurrentUserId = localStorage.getItem('userId');

    //object masking for api call
    const editAddressObjMasking = {
      ...value,
      favoritePickupLocationFor: value.favoritePickupLocationFor
        ? [{ customerId: getCurrentUserId }]
        : [],
      favoriteDeliveryLocationFor: value.favoriteDeliveryLocationFor
        ? [{ customerId: getCurrentUserId }]
        : [],
      defaultLocationFor: value.defaultLocationFor
        ? [{ customerId: getCurrentUserId }]
        : [],
      Customer_ontime_id,
      customerId: currentUserObjectId,
      addressId: initialValues?._id,
      addressLine1: manipulateString(value?.addressLine1),
    };
    const addAddressObjMasking = {
      ...value,
      favoritePickupLocationFor: value.favoritePickupLocationFor
        ? [{ customerId: getCurrentUserId }]
        : [],
      favoriteDeliveryLocationFor: value.favoriteDeliveryLocationFor
        ? [{ customerId: getCurrentUserId }]
        : [],
      defaultLocationFor: value.defaultLocationFor
        ? [{ customerId: getCurrentUserId }]
        : [],
      ...googlePlaceApiData,
      Customer_ontime_id,
      customerId: currentUserObjectId,
      addressLine1: manipulateString(value?.addressLine1),
    };

    if (editMode) {
      dispatch(editAddress({ editAddressObjMasking, setOpenModel }));
      return;
    } else {
      dispatch(addAddress({ addAddressObjMasking, setOpenModel }));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      companyName: editMode ? initialValues?.['Company Name'] : '',
      contactName: editMode ? initialValues?.['Contact Name'] : '',
      addressLine1: editMode ? initialValues?.Address : '',
      addressLine2: editMode ? initialValues?.['Address Line 2'] : '',
      comments: editMode ? initialValues?.['Comments'] : '',
      city: editMode ? initialValues?.City : '',
      postalCode: editMode ? initialValues?.['Postal Code'] : '',
      email: editMode ? initialValues?.['Email'] : '',
      phone: editMode ? initialValues?.['Phone'] : '',
      favoritePickupLocationFor: editMode ? initialValues?.favPickup : false,
      favoriteDeliveryLocationFor: editMode
        ? initialValues?.favDelivery
        : false,
      defaultLocationFor: editMode ? initialValues?.defaultLocation : false,
      latitudeLongitude: editMode ? initialValues?.latitudeLongitude : '',
      phoneExt: editMode ? initialValues?.phoneExt : '',
    });
  }, [initialValues]);

  const onCancel = () => {
    setOpenModel(false);
    form.resetFields();
  };

  const locationSearchValidator = () => {
    form.setFieldsValue({ addressLine1: '', city: '', postalCode: '' });
    setGooglePlaceApiData(null);
  };

  return (
    <>
      <Modal
        title={editMode ? 'Edit Location' : 'Add Location'}
        forceRender
        open={openModel}
        className='address-model'
        onCancel={onCancel}
        maskClosable={false}
      >
        <Form
          name='Address Popup'
          form={form}
          onFinish={formSubmitHandler}
          className='address-popup-form'
        >
          <div className='form-line'>
            <Form.Item name='companyName'  rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Company name is required',
                },
              ]}>
              <Input placeholder='Company Name' className='popup-inputs' />
            </Form.Item>

            <Form.Item name='contactName'>
              <Input placeholder='Contact Name' className='popup-inputs' />
            </Form.Item>
          </div>

          <Form.Item shouldUpdate={() => openModel} noStyle>
            {() => {
              return (
                <Autocomplete
                  onLoad={onLoad}
                  onPlaceChanged={place => onPlaceChanged(place)}
                  
                >
                  <Form.Item
                    name='addressLine1'
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: 'Address line 1 is required',
                      },
                    ]}
                  >
                    <Input
                      placeholder='Address line 1'
                      className='popup-inputs'
                      onInput={locationSearchValidator}
                    />
                  </Form.Item>
                </Autocomplete>
              );
            }}
          </Form.Item>

          <Form.Item name='addressLine2'>
            <Input placeholder='Address line 2' className='popup-inputs' />
          </Form.Item>

          <Form.Item name='comments'>
            <Input placeholder='Comment' className='popup-inputs' />
          </Form.Item>
          <div className='form-line'>
            <Form.Item name='city'  rules={[
                {
                  required: true,
                  message: 'City is required',
                },
              ]}>
              <Input placeholder='City' className='popup-inputs' disabled />
            </Form.Item>

            <Form.Item
              name='postalCode'
              rules={[
                {
                  required: true,
                  message: 'Postal code is required',
                },
              ]}
            >
              <InputMask
                autoClear={false}
                slotChar=''
                mask={'*** ***'}
                value={editMode ? initialValues?.['Postal Code'] : ''}
                placeholder='Postal Code'
                className='masked-input'
                disabled
              />
            </Form.Item>
          </div>

          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                whitespace: true,
                pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: 'Please enter valid email',
              },
            ]}
          >
            <Input placeholder='Email' className='popup-inputs' />
          </Form.Item>

          <div className='form-line-footer'>
            <Form.Item
              name='phone'       
              validateTrigger={['onFinish', 'onComplete']}
              rules={[
                {
                  required: true,
                  message: 'Phone number is required',
                },
              ]}
            >
              <InputMask
                mask={'(999)-999-9999'}
                type='tele'
                placeholder='(999) 999-9999'
                className='popup-inputs masked-input'
              />
            </Form.Item>

            <Form.Item name={'phoneExt'}>
              <Input placeholder='PhoneExt' className='popup-inputs' />
            </Form.Item>
          </div>
          <div className='address-popup-controller'>
            <div className='add-fav'>
              <span>Add to Favourite</span>
              <Form.Item
                label='Pickup'
                valuePropName='checked'
                name={'favoritePickupLocationFor'}
                className='switches'
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label='Delivery'
                valuePropName='checked'
                name={'favoriteDeliveryLocationFor'}
                className='switches'
              >
                <Switch />
              </Form.Item>
            </div>
            <div className='to-default'>
              <span>Add to Default</span>
              <Form.Item
                label='Pickup'
                valuePropName='checked'
                name={'defaultLocationFor'}
                className='switches'
              >
                <Switch />
              </Form.Item>
            </div>
          </div>
          <button type='submit' className='address-popup-form-btn'>
            Save
          </button>
        </Form>
      </Modal>
    </>
  );
};

export default AddressPopup;
