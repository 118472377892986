import { createSlice } from '@reduxjs/toolkit';

export const UnsubscribeSlice = createSlice({
  name: 'unsubscribe',
  initialState: {
    status: false,
  },
  reducers: {
    setMailService(state, { payload }) {
      state.status = payload;
    },
  },
});

export const { setMailService } = UnsubscribeSlice.actions;

export default UnsubscribeSlice.reducer;
